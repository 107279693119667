import { Controller, useForm, useFormContext } from "react-hook-form";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import MultiFileUpload from "../../../../common/MultiFileUpload";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import FileService from "../../../../../services/fileService";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const Densities = (props: any) => {
  const { control } = useForm<any>({ defaultValues: props.initialData });
  const methods = useFormContext();
  const dispatch = useDispatch();
  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "method_number": {
        let displayNames = props.material?.solid?.densities?.attachments?.method_number ?? [];
        displayNames = displayNames?.map((attachment: any) => attachment.display_name);
        const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
          if (displayNames.includes(e?.target?.files?.[key].name)) {
            return true;
          }
        });
        if (isDuplicate) {
          props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
          setTimeout(() => props.dispatch(alertCloseAction()));
          return;
        }
        methods.setValue("densities.attachments.method_number", [
          ...(methods.getValues("densities.attachments.method_number") || []),
          ...Object.keys(e?.target?.files ?? []).map(
            (key: any) => {
              const fileSize = e?.target?.files?.[key]?.size ?? 0;
              return {
                category: "Method Number",
                file: e?.target?.files?.[key],
                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
              };
            }),
        ], { shouldDirty: true, shouldTouch: true });
      }
        break;
      case "sop": {
        let displayNames = props.material?.solid?.densities?.attachments?.sop ?? [];
        displayNames = displayNames?.map((attachment: any) => attachment.display_name);
        const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
          if (displayNames.includes(e?.target?.files?.[key].name)) {
            return true;
          }
        });
        if (isDuplicate) {
          props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
          setTimeout(() => props.dispatch(alertCloseAction()));
          return;
        }
        methods.setValue("densities.attachments.sop", [
          ...(methods.getValues("densities.attachments.sop") || []),
          ...Object.keys(e?.target?.files ?? []).map(
            (key: any) => {
              const fileSize = e?.target?.files?.[key]?.size ?? 0;
              return {
                category: "SOP",
                file: e?.target?.files?.[key],
                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
              };
            }),
        ], { shouldDirty: true, shouldTouch: true });
      }
        break;
    }
  };

  // Remove selected file
  const removeFile = (index: number, name: string) => {
    switch (name) {
      case "method_number":
        methods.setValue("densities.attachments.method_number", (methods.getValues("densities.attachments.method_number") || []).filter((file: any, i: number) => i !== index), {
          shouldDirty: true,
          shouldTouch: true,
        });
        break;
      case "sop":
        methods.setValue("densities.attachments.sop", (methods.getValues("densities.attachments.sop") || []).filter((file: any, i: number) => i !== index), {
          shouldDirty: true,
          shouldTouch: true,
        });
        break;
      default:
      // default
    }
  };

  async function downloadFile(file: any) {

    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="bulk_densities" title="Bulk Densities (g/ml)">
              <span className="form-label">Bulk Densities (g/ml)</span>
              {!props.readable ? (
                <Controller
                  name="densities.bulk_densities"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.densities?.bulk_densities ?? ""}
                  render={({ field }) => <input type="number"
                                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.bulk_densities}</p>
              )}
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="tapped_density" title="Tapped Density (g/ml)">
              <span className="form-label">Tapped Density (g/ml)</span>
              {!props.readable ? (
                <Controller
                  name="densities.tapped_density"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.densities?.tapped_density ?? ""}
                  render={({ field }) => <input type="number"
                                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.tapped_density}</p>
              )}
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="eln_number" title="ELN Number">
              <span className="form-label">ELN Number</span>
              {!props.readable ? (
                <Controller
                  name="densities.eln_number"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.densities?.eln_number ?? ""}
                  render={({ field }) => <input className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
              )}
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label
              className="ip-wrap"
              htmlFor="regulatory_filing"
              title="Regulatory Filing"
            >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
              <div className="input-wrap select-outer">
                {!props.readable ? (
                  <Controller
                    name="densities.regulatory_filing"
                    control={methods.control}
                    defaultValue={methods.control._formValues?.densities?.regulatory_filing ?? ""}
                    render={({ field }) =>
                      <select className="theme-ip" {...field}>
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>}
                  />
                ) : (
                  <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                )}
              </div>
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="method_number" title="Method Number">
              <span className="form-label">Method Number</span>
              {!props.readable ? (
                <Controller
                  name="densities.method_number"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.densities?.method_number ?? ""}
                  render={({ field }) => <input type="number"
                                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.method_number}</p>
              )}
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <MultiFileUpload
            title="SOP"
            name="sop"
            readable={props?.readable ?? false}
            handleFileChange={handleFileChange}
            removeFile={removeFile}
            attachments={props.readable ? control?._formValues?.attachments?.sop : methods.control._formValues?.densities?.attachments?.sop}
            downloadFile={downloadFile}
          />
        </div>
      </div>
    </>
  );
};

export default Densities;