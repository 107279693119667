import { FC, useState, useLayoutEffect, useEffect } from "react";

import Layout from "../../components/layout";
import "./style.scss";

import IconsWrapper from "../../components/process-automation/iconsWrapper";
import Visualization from "../../components/process-automation/visualization";
import WorkBench from "../../components/process-automation/workBench";
import { IIconProps } from "./interface";

const ProcessAutomation: FC = () => {
  const [droppedItem, setDroppedItem] = useState<any>({
    csv: [] as IIconProps[],
    automation: {} as IIconProps,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parsedCsvFiles, setParsedCsvFiles] = useState([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [csvFields, setCsvFields] = useState<string[]>([]);

  return (
    <Layout title="Data Warehouse">
      <div className="sec-info control-head">
        <h1 className="head-lg">Data Warehouse</h1>
      </div>
      <div className="theme-card automation admin-h">
        <div className="body">
          <div className="row height-full process-automation">
            <div className={"container-1"}>
              <IconsWrapper />
              <WorkBench
                droppedItem={droppedItem}
                parsedCsvFiles={parsedCsvFiles}
                setCsvFields={setCsvFields}
                setDroppedItem={setDroppedItem}
                setIsAnalyzing={setIsAnalyzing}
                setIsModalOpen={setIsModalOpen}
                setParsedCsvFiles={setParsedCsvFiles}
              />
            </div>
            <Visualization
              csvFields={csvFields}
              isAnalyzing={isAnalyzing}
              isModalOpen={isModalOpen}
              parsedCsvFiles={parsedCsvFiles}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProcessAutomation;
