import React, { useMemo } from "react";
import { sanitizeText } from "../utils/common";
import { AuthState } from "@okta/okta-auth-js";

export default function useUserInitials(oktaAuthState: AuthState | null) {
  return useMemo(() => {

    let userFullName: string = sanitizeText(
      oktaAuthState?.idToken?.claims?.name ?? "",
    );
    userFullName = userFullName.toUpperCase();
    let userName = userFullName?.split(" ");
    return userName
      ? userName[0].charAt(0) +
      (userName?.length > 1
        ? userName[1].charAt(0)
        : "")
      : "";
  }, [oktaAuthState]);
}
