import React from "react";
import AmazonQService from "../../services/amazonQService";
import { disableInput } from "./utils";

//@ts-ignore
function ActionProvider({ createChatBotMessage, setState, children }) {

  async function askAmazonQ(query: string) {
    disableInput();
    const res = await AmazonQService.askAmazonQ(query);
    if (res.status === 200) {
      const message = res.data.body.awsQ.systemMessage;
      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, createChatBotMessage(message)],
      }));
    }
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            askAmazonQ,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;