import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// components
import Layout from "../../components/layout";
import MultiFileUpload from "../../components/common/MultiFileUpload";

// modals
import BackConfirmModal from "../../components/modals/BackFormModal";
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// services
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// props
import { RootState } from "../../redux/store";
import { FilesProps, MaterialData } from "../../types";
import { VendorProps } from "../../types/materialsTypes";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { findFile, getUpdatedFiles, limitDigits, setTitleNumberInput, sortItemsByKey } from "../../utils/common";
import { attachmentsTypes, checkDuplicateFiles, vendorNames } from "../../utils/materialHelper";
import { UPDATE_MATERIAL_SUCCESS_MESSAGE, MAX_FILESIZE_ERROR_MESSAGE, DUPLICATE_FILE_ERROR_MESSAGE, ERROR_MESSAGE } from "../../utils/constant";
import { MaterialHelp } from "../../utils/helpContent";

const schema = yup.object({
  material_name: yup.string().required("Please enter material name."),
  vendor_name: yup.string().required("Please enter vendor name."),
  lot_number: yup.string().required("Please enter lot number."),
});

const MaterialUpdate = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params: any = useParams();
  const { state }: any = useLocation();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';

  // user context
  const { user } = useUser();

  // Redux data
  const material = useSelector((state: RootState) => state?.material);

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openBack, setOpenBack] = useState<boolean>(false);
  const [materialId, setMaterialId] = useState<any>();
  const [materialData, setMaterialData] = useState<any>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<string>(attachmentsTypes[0].value);
  const [attachments, setAttachments] = useState<FilesProps[] | []>([]);
  const [documents, setDocuments] = useState<FilesProps[] | []>([]);
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  // form
  const initialData: MaterialData = {
    material_name: materialData?.material_name ?? null,
    vendor_name: materialData?.vendor_name ?? null,
    phase: materialData?.phase ?? null,
    lot_number: materialData?.lot_number ?? null,
    abbreviation: materialData?.abbreviation ?? null,
    brand_name: materialData?.brand_name ?? null,
    synonyms: materialData?.synonyms ?? null,
    boiling_point: materialData?.boiling_point ?? null,
    cas_number: materialData?.cas_number ?? null,
    chemical_formula: materialData?.chemical_formula ?? null,
    incompatibility: materialData?.incompatibility ?? null,
    shelf_life: materialData?.shelf_life ?? null,
    melting_point: materialData?.melting_point ?? null,
    price: materialData?.price ?? null,
    molecular_weight: materialData?.molecular_weight ?? null,
    purity: materialData?.purity ?? null,
    smiles: materialData?.smiles ?? null,
    manufacturing_number: materialData?.manufacturing_number ?? null,
    project_name: materialData?.project_name ?? null,
    process_name: materialData?.process_name ?? null,
    chemical_safety: materialData?.chemical_safety ?? null,
    regulatory_filing_data: materialData?.regulatory_filing_data ?? null,
  };

  const { control, handleSubmit, formState: { errors, isDirty, dirtyFields }, reset } = useForm<MaterialData>({
    resolver: yupResolver(schema), defaultValues: initialData
  });

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener('beforeunload', refreshData);
    return () => {
      window.removeEventListener('beforeunload', refreshData);
    };
  }, []);

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, []);

  useEffect(() => {
    if (user) {
      const { userType, vendors } = user;
      if (userType.includes('external')) {
        // okta dashboard vendors for external users.
        setVendors(sortItemsByKey([
          { label: 'Generic', value: 'Generic' },
          ...vendors.map((vendor: any) => ({ label: vendor, value: vendor })),
        ], 'label'));
      }
    }
  }, [user]);

  useEffect(() => {
    if (params && params?.id) {
      setMaterialId(params?.id)
    }
  }, [params]);

  useEffect(() => {
    if (materialId) getMaterial();
  }, [materialId]);

  const getMaterial = async () => {
    setLoading(true); // enable loading
    const res = await MaterialService.getById(params?.id);
    setLoading(false); // disable loading

    if (res?.status === 200) {
      dispatch(materialAction(res?.data?.body?.Item));
      setMaterialData(res?.data?.body?.Item);
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  useEffect(() => {
    setMaterialData(material?.item);
    material.item ? setCompleted(true) : '';
    reset(material.item);

    if (material?.item?.attachments?.length) {
      let newAttachments: any = [];
      let newDocuments: any = [];
      if (!attachments.length && !documents.length) {
        material?.item?.attachments?.forEach((attachment: any) => {
          let e: any = {};
          let d: any = {};
          if (attachment.category === 'Molecular Structure' ||
            attachment.category === 'USP Monograph' ||
            attachment.category === 'Chemical Safety' ||
            attachment.category === 'HPLC Method' ||
            attachment.category === 'SMILES' ||
            attachment.category === 'Others'
          ) {
            e.category = attachment.category;
            e.error = false;
            e.isUpload = true;
            e.file = {
              name: attachment.display_name,
              type: attachment.mime_type,
            };
            newAttachments.push(e);
          }
          if (attachment.category === 'Process Attachment') {
            d.category = attachment.category;
            d.error = false;
            d.isUpload = true;
            d.file = {
              name: attachment.display_name,
              type: attachment.mime_type,
            };
            newDocuments.push(d);
          }
        });
        setAttachments(newAttachments);
        setDocuments(newDocuments);
      }
    }
  }, [material.item]);

  // method to select file.
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'processAttachments') {
      setDocuments([...documents, ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: 'Process Attachment',
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        })]);
      return;
    }
    setAttachments([
      ...attachments,
      ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: selectedAttachmentType,
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        }),
    ]);
  }

  // method to remove selected file.
  const removeFile = (index: number, name: string) => {
    if (name === 'processAttachments') {
      setDocuments(documents.filter((document, i) => i !== index));
      return;
    }
    setAttachments(attachments.filter((attachment, i) => i !== index));
  }

  const uploadFile = async (file: any) => {
    let newAttachment = await findFile(attachments, file);
    if (!newAttachment) {
      newAttachment = await findFile(documents, file);
    }
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: 'PUT',
          body: blob,
        })
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  }

  const uploadFileAPI = async (data: any, attachmentList: any[], id?: string) => {
    if (attachmentList.length) {
      // file upload request
      const filesResponse = await FileService.create("/files/upload", {
        id: material?.item?.id ?? id,
        file_for: 'Material',
        files: attachmentList,
      });

      if (filesResponse?.status === 200) {
        const filesResult = filesResponse?.data;
        return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
          .then(async (res) => {
            // update material object
            filesResult.body.files.map((file: any) => delete file.signedUrl);

            if (material?.item?.attachments) {
              filesResult.body.files = [...material?.item?.attachments, ...filesResult.body.files];
            };

            filesResult.body.files = await getUpdatedFiles(filesResult?.body?.files ?? [], [...attachments, ...documents], !0);

            // material update request
            const materialResponse = await MaterialService.update({
              ...data,
              id: material?.item?.id ?? id,
              uid: `${auth?.claims?.uid}`,
              attachments: filesResult.body.files
            });

            if (materialResponse?.status === 200) {
              dispatch(materialAction(materialResponse?.data?.body));
              setCompleted(true);
              return true;
            }
            return false;
          })
          .catch(err => {
            return false;
          });
      }
      return false;
    }
    return true;
  }

  const apiRequest = async (data: any, newAttachments: any[]) => {
    // attachments list
    let attachmentList = newAttachments.filter((attachment: any) => !attachment.isUpload).map(
      (attachment) => ({ category: attachment?.category, key: attachment.file?.name, mimeType: attachment?.file?.type }));

    if (attachmentList.length) {
      // upload files API
      return await uploadFileAPI(data, attachmentList);
    }

    const payload = {
      ...data,
      id: material?.item?.id,
      uid: `${auth?.claims?.uid}`,
      attachments: material?.item?.attachments?.reduce((e: any[], t: any) => {
        let newItem = attachments.find(
          (attachment: any) => (attachment.category === t.category && attachment?.file?.name === t.display_name));
        if (!newItem) {
          newItem = documents.find((document: any) => (document.category === t.category && document?.file?.name.toLocaleLowerCase().replaceAll(' ', '-') === t.display_name));
        }
        if (newItem) {
          e = [...e, t];
        }
        return e;
      }, []),
    };

    // material update request
    const materialResponse = await MaterialService.update(payload);
    if (materialResponse?.status === 200) {
      dispatch(materialAction(materialResponse?.data?.body));
      setCompleted(true);
      return true;
    }

    return false;
  }

  const validateForm = async () => {
    const invalidateFiles = [...attachments, ...documents].find((attachment: any) => attachment.error);
    if (invalidateFiles) { // Check invalid file
      dispatch(alertOpenAction(MAX_FILESIZE_ERROR_MESSAGE, 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    const isDuplicate = await (await checkDuplicateFiles([...attachments, ...documents]))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction(DUPLICATE_FILE_ERROR_MESSAGE, 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  }

  // Form submit
  const onSubmit: SubmitHandler<any> = async () => {
    if (await validateForm()) {
      setLoading(true); // enable loading
      const apiResponse = await apiRequest(control._formValues, [...attachments, ...documents]);
      setLoading(false); // disble loading
      if (apiResponse) {
        dispatch(alertOpenAction(UPDATE_MATERIAL_SUCCESS_MESSAGE, 'success'));
      } else {
        dispatch(alertOpenAction(ERROR_MESSAGE, 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Tabs
  const phaseTab = () => {
    if (materialData.phase == "Solid") {
      history.push({
        pathname: `/materials/${params?.id}/phase/solid`,
        state: 'find'
      })
    } else if (materialData.phase == "Gas") {
      history.push({
        pathname: `/materials/${params?.id}/phase/gas`,
        state: 'find'
      })
    } else if (materialData.phase == "Liquid") {
      history.push({
        pathname: `/materials/${params?.id}/phase/liquid`,
        state: 'find'
      })
    }
  }

  const solubilityTab = () => {
    history.push({
      pathname: `/materials/${params?.id}/solubility`,
      state: 'find'
    })
  }

  const environmentalDataTab = () => {
    history.push({
      pathname: `/materials/${params?.id}/environmental-data`,
      state: 'find'
    })
  }

  const spectralInfoTab = () => {
    history.push({
      pathname: `/materials/${params?.id}/spectral-information`,
      state: 'find'
    })
  }

  //Cancel button functions
  const saveMaterial = async () => {
    if (materialData) {
      setOpen(false);
      setOpenBack(false);
      if (await validateForm()) {
        setLoading(true); // enable loading
        const apiResponse = await apiRequest(control._formValues, [...attachments, ...documents]);
        setLoading(false); // disable loading
        if (apiResponse) {
          dispatch(alertOpenAction('Material updated successfully.', 'success'));
          dontSave();
          // history.push('/materials');
        } else {
          dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction('No data updated to save.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  // const dontSave = () => {
  //   setOpen(false);
  //   setOpenBack(false);
  //   history.push('/materials');
  // }

  const dontSave = () => {
    if (state?.operation == 'cloneFromList' || state?.page == 'list') {
      history.push(`/materials/list`);
    } else if (state?.operation == 'cloneFromFind' || state == 'find') {
      history.push(`/materials/find`);
    } else {
      history.push(`/materials/`);
    }
  }

  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite??false;

  return (
    <Layout title="Material Update">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={MaterialHelp.title}
        content={MaterialHelp.content} />
      <BackConfirmModal
        open={openBack}
        setOpen={setOpenBack}
        saveMaterial={saveMaterial}
        dontSave={dontSave}
      />
      <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={dontSave} />
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit);
      }}>
        <div className="sec-info control-head">
          <div className="back">
            <button title="Back" className="icon-btn alter" onClick={() => {
              if (isDirty || Object.keys(dirtyFields).length) {
                setOpenBack(true);
                return;
              }
              history.push(`/materials`);
            }}>
              <i className="fa-solid fa-arrow-left" />
            </button>
          </div>
          <div className="head">
            <h1 className="head-lg">Update Material</h1>
          </div>
          <div className="right">
            {hasWritePermission && (
              <button
                type="submit"
                title="Save"
                disabled={loading ? true : false}
                className={`icon-btn ${loading ? "disabled  " : ""}`}
                onClick={onSubmit}
              >
                <i className="fa-regular fa-floppy-disk" />
              </button>
            )}
            <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
              if (isDirty || Object.keys(dirtyFields)?.length ||
                (Array.isArray(material?.item?.attachments) && (material?.item?.attachments?.length !== [...attachments, ...documents]?.length))
              ) {
                setOpen(true);
                return;
              }
              history.push(`/materials`);
            }}>
              <i className="fa-solid fa-xmark" />
            </Link>
            <Link to="#" className="icon-btn alter" title="Help" onClick={() => { setOpenHelp(true) }}>
              <i className="fa-solid fa-question" />
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="theme-card">
              <div className="head">
                <div className="admin-tabs">
                  <ul className="inner">
                    <li>
                      <Link to="#" className={`tab-link ${completed ? "done" : ""} active  `}>
                        Material
                      </Link>
                    </li>
                    <li>
                      <button type="button" className={`tab-link ${materialData && (materialData?.gas || materialData?.solid || materialData?.liquid) ? 'done' : ''}`}
                              onClick={() => phaseTab()}>
                        Phase
                      </button>
                    </li>
                    <li>
                      <button type="button" className={`tab-link ${materialData && materialData?.solubility ? 'done' : ''}`}
                              onClick={() => solubilityTab()}>
                        Solubility
                      </button>
                    </li>
                    <li>
                      <button type="button" className={`tab-link ${materialData && materialData?.spectralInfo ? 'done' : ''}`}
                              onClick={() => spectralInfoTab()}>
                        Spectral Information
                      </button>
                    </li>
                    <li>
                      <button type="button" className={`tab-link ${materialData && materialData?.environment ? 'done' : ''}`}
                              onClick={() => environmentalDataTab()}>
                        Environmental Data
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="materialName">
                            <span className="form-label">Material Name</span>
                            <Controller
                              name="material_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                            {errors.material_name && <p className="error">{errors.material_name?.message}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="vendor">
                            <span className="form-label">Vendor</span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="vendor_name"
                                control={control}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field}>
                                    <option value="" disabled selected>Select</option>
                                    {vendors?.map((vendor: VendorProps, index: number) => (
                                      <option key={index} value={vendor?.value}>
                                        {vendor.label}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                              {errors.vendor_name && <p className="error">{errors.vendor_name?.message}</p>}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="phase">
                            <span className="form-label">Phase</span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="phase"
                                control={control}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field} disabled={materialData ? true : false}>
                                    <option value="" disabled selected>Select</option>
                                    <option value="Gas">Gas</option>
                                    <option value="Liquid">Liquid</option>
                                    <option value="Solid">Solid</option>
                                  </select>
                                )}
                              />
                              {errors.phase && <p className="error">{errors.phase?.message?.toString()}</p>}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="abbreviation" title="Abbreviation">
                            <span className="form-label">Abbreviation</span>
                            <Controller
                              name="abbreviation"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="brandName" title="Brand Name">
                            <span className="form-label">Brand Name</span>
                            <Controller
                              name="brand_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="synonyms" title="Synonyms">
                            <span className="form-label">Synonyms</span>
                            <Controller
                              name="synonyms"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="boilingPoint" title="Boiling Point (C)">
                            <span className="form-label">Boiling Point (C)</span>
                            <Controller
                              name="boiling_point"
                              control={control}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} step="any" className="theme-ip" {...field} />}
                            />
                            {errors.boiling_point && <p>{errors.boiling_point?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="cas_number" title="CAS Number">
                            <span className="form-label">CAS Number</span>
                            <Controller
                              name="cas_number"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="chemical_formula" title="Chemical Formula">
                            <span className="form-label">Chemical Formula</span>
                            <Controller
                              name="chemical_formula"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="incompatibility" title="Incompatibility">
                            <span className="form-label">Incompatibility</span>
                            <Controller
                              name="incompatibility"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="shelf_life" title="Decomposition/Shelf-life (Month)">
                            <span className="form-label">Decomposition/Shelf-life (Month)</span>
                            <Controller
                              name="shelf_life"
                              control={control}
                              render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="chemical_safety" title="Chemical Safety">
                            <span className="form-label">Chemical Safety</span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="chemical_safety"
                                control={control}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field}>
                                    <option value="" disabled selected>Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                )}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="melting_point" title="Melting Point (C)">
                            <span className="form-label">Melting Point (C)</span>
                            <Controller
                              name="melting_point"
                              control={control}
                              render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                            {errors.melting_point && <p className="error">{errors.melting_point?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="price" title="Price ($/g)">
                            <span className="form-label">Price ($/g)</span>
                            <Controller
                              name="price"
                              control={control}
                              render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                            {errors.price && <p className="error">{errors.price?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="molecular_weight" title="Molecular Weight">
                            <span className="form-label">Molecular Weight (g/mol)</span>
                            <Controller
                              name="molecular_weight"
                              control={control}
                              render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                            {errors.molecular_weight && <p>{errors.molecular_weight?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="purity">
                            <span className="form-label">
                              Purity (%)
                            </span>
                            <Controller
                              name="purity"
                              control={control}
                              render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="smiles" title="SMILES">
                            <span className="form-label">SMILES</span>
                            <Controller
                              name="smiles"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                      title="Upload File"
                      name="uploadFiles"
                      hasAttachmentTypes={true}
                      attachmentsTypes={attachmentsTypes}
                      setSelectedAttachmentType={setSelectedAttachmentType}
                      handleFileChange={handleFileChange}
                      removeFile={removeFile}
                      attachments={attachments}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="divider"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="lot_number">
                            <span className="form-label">Lot Number</span>
                            <Controller
                              name="lot_number"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                          {errors.lot_number && <p className="error">{errors.lot_number?.message?.toString()}</p>}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="manufacturing_number" title="Manufacturing Number">
                            <span className="form-label">Manufacturing Number</span>
                            <Controller
                              name="manufacturing_number"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="project_name" title="Project Name">
                            <span className="form-label">Project Name</span>
                            <Controller
                              name="project_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label
                            className="ip-wrap"
                            htmlFor="regulatory_filing_data"
                          >
                            <span className="form-label">
                              Regulatory Filing Data
                            </span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="regulatory_filing_data"
                                control={control}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                  </select>
                                )}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="process_name" title="Process Name">
                            <span className="form-label">Process Name</span>
                            <Controller
                              name="process_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <MultiFileUpload
                      title="Process Attachment"
                      name="processAttachments"
                      handleFileChange={handleFileChange}
                      removeFile={removeFile}
                      attachments={documents}
                    />
                  </div>
                </div>
              </div>
              {loading ? <div className="theme-loader show fixed">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div> : ''}
            </div>
          </div>
        </div>
        {loading ?
          <div className="theme-loader show fixed">
            <div className="loader-outer">
              <div className="loader"></div>
            </div>
          </div> : ''}
      </form>
    </Layout >
  );
};

export default MaterialUpdate;
