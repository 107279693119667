import { FC } from "react";
import { useDrag } from "react-dnd";

interface IProps {
  icon: React.ReactNode;
  id: number;
  type: string;
  arrowId: string;
  title?: string;
  className?: string;
}

export const Icons: FC<IProps> = ({ id, type, icon, arrowId, title }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "icon",
    item: { id, icon, type, title },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <>
      <div
        style={{ opacity: isDragging ? 0.6 : 1 }}
        id={arrowId}
        ref={dragRef}
        className={`drop-box radius-6 ${
          title ? "with-title" : ""
        } cursor-pointer`}
      >
        {icon}
        {title && <p>{title}</p>}
      </div>
    </>
  );
};
