import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import { Dropdown } from "semantic-ui-react";
import { Layout } from "plotly.js";

const ChartColorsForProcessAutomation: { [key: string]: string } = {
  "Concentration A": "green",
  Impurity: "black",
  Level: "yellow",
  Temperature: "#f6cd8b",
};

interface IProps {
  chartData: { [key: string]: any }[];
  keyName: string;
  label: string;
  csvFields: string[];
}

const BarChart: FC<IProps> = ({ chartData, keyName, label, csvFields }) => {
  const [selectedGraph, setSelectedGraph] = useState<string>(keyName);
  const [width, setWidth] = useState<number>(500); // Initial width fallback
  const graphContainerRef = useRef<HTMLDivElement>(null);

  const fields = [...csvFields];
  const fieldsWithoutTime = fields.filter((item) => !item.includes("Time"));
  const labels = chartData?.map((item) => item[csvFields[0]]);

  const dropdownOptions = fieldsWithoutTime.map((element, index) => ({
    key: index + 1,
    text: element,
    value: element,
  }));

  const data: Partial<Plotly.PlotData>[] = [
    {
      x: labels,
      y: chartData?.map((item) => item[selectedGraph]),
      type: "scatter",
      mode: "lines+markers",
      marker: {
        color: "#1E8EFE",
      },
      name: "",
    },
  ];

  const layout: Partial<Layout> = {
    title: `${selectedGraph} representation by Time`,
    xaxis: {
      title: "Time",
    },
    yaxis: {
      title: selectedGraph,
      zeroline: false,
    },
    showlegend: true,
    width: width,
  };

  useEffect(() => {
    const updateWidth = () => {
      if (graphContainerRef.current) {
        setWidth(graphContainerRef.current.offsetWidth - 40); // Adjust for padding/margin if necessary
      }
    };

    updateWidth(); // Initial update

    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    if (graphContainerRef.current) {
      resizeObserver.observe(graphContainerRef.current);
    }

    return () => {
      if (graphContainerRef.current) {
        resizeObserver.unobserve(graphContainerRef.current);
      }
    };
  }, []);

  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <Dropdown
          inline
          options={dropdownOptions}
          defaultValue={keyName}
          onChange={(e, { value }) => setSelectedGraph(value as string)}
        />
      </div>
      <div id="graph-container" ref={graphContainerRef}>
        <Plot className="automation-graph" data={data} layout={layout} />
      </div>
      <div className="divider" />
    </Fragment>
  );
};

export default BarChart;
