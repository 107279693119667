import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Layout from "../../components/layout";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";

// helpers

// imgaes
import PilotImage from "../../assets/internal-process-dashboard/pilot.jpg";
import RnDImage from "../../assets/internal-process-dashboard/rnd.png";
import GatewayImage from "../../assets/internal-process-dashboard/gateways.png";
import ReportImage from "../../assets/internal-process-dashboard/reports.png";

export default function InternalProcessIndex() {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  const hasReadPermission =
    user?.permissions?.updated?.material?.hasRead ?? false;
  const hasWritePermission =
    user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  return (
    <Layout title="Internal Processes">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Internal Processes"}
        content={""}
      />
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">Internal Processes</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link
            to="#"
            title="Help"
            className="icon-btn alter"
            onClick={() => {
              setOpenHelp(true);
            }}
          >
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h" style={{}}>
        <div className="body">
          <div className="md-container-2">
            <div className="row justify-around btn-list">
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "40%" }}>
                      <img src={RnDImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">R&D</span>
                  </span>
                </button>
              </div>
              <div className="col-md-5">
                <Link
                  to="/internal-processes/gateways"
                  title="Gateways"
                  className="theme-btn invert-colors w-full brick fs-28"
                >
                  <span className="flex items-center w-full">
                    <div style={{ width: "32%" }}>
                      <img src={GatewayImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Gateways</span>
                  </span>
                </Link>
              </div>
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "40%" }}>
                      <img src={PilotImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Pilot</span>
                  </span>
                </button>
              </div>
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "35%" }}>
                      <img src={ReportImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Reports</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

// export default PermissionsWrapper(MaterialIndex);
