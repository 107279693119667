import React, { useState } from "react";
import Layout from "../../components/layout";
import HelpModal from "../../components/modals/HelpModal";
import { Link } from "react-router-dom";
import ReactionImage from "../../assets/models/reaction.jpg";
import CrystallizationImage from "../../assets/models/crystallization.jpg";
import DynochemImage from "../../assets/models/dynochem.jpeg";
import GPROMSImage from "../../assets/models/gPROMS.png";
import StarCCA from "../../assets/models/star-ccm.jpeg";
import Synthia from "../../assets/models/synthia.jpeg";

export default function ModelsAndSimulation({}) {
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  const handleClick = () => {
    window.open('https://starccm.pro-pem.com/', '_blank');
  };

  return (
    <Layout title="Models and Simulation">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Internal Processes"}
        content={""}
      />
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">Models and Simulation</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link
            to="#"
            title="Help"
            className="icon-btn alter"
            onClick={() => {
              setOpenHelp(true);
            }}
          >
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h" style={{}}>
        <div className="body md-container-2">
          <div className="row justify-around btn-list">
            <div className="col-md-5">
              <Link
                to="/models-and-simulation/reaction"
                title="Reaction"
                className="theme-btn invert-colors brick w-full fs-28"
              >
                <img
                  src={ReactionImage}
                  alt="Reaction Image"
                  style={{ width: "50px" }}
                />
                <span className="flex-1" style={{ marginLeft: "-1.95rem" }}>
                  Reaction
                </span>
              </Link>
            </div>
            <div className="col-md-5">
              <Link
                data-cy="to-crystallization"
                to="/models-and-simulation/crystallization"
                title="Crystallization"
                className="theme-btn invert-colors brick w-full fs-28"
              >
                <img
                  src={CrystallizationImage}
                  alt="Crystallization Image"
                  style={{ width: "50px" }}
                />
                <span className="flex-1" style={{ marginLeft: "-0.45rem" }}>
                  Crystallization
                </span>
              </Link>
            </div>
            <div className="col-md-5">
              <a
                className="theme-btn invert-colors brick w-full fs-28"
                href="https://account.scale-up.com/signin"
                target="_blank"
              >
                <img
                  src={DynochemImage}
                  alt="Dynochem Image"
                  style={{ width: "50px" }}
                />
                <span className="flex-1" style={{ marginLeft: "-1rem" }}>
                  Dynochem
                </span>
              </a>
            </div>
            <div className="col-md-5">
              <button className="theme-btn invert-colors brick w-full fs-28">
                <img
                  src={GPROMSImage}
                  alt="gPROMS Image"
                  style={{ width: "50px" }}
                />
                <span className="flex-1" style={{ marginLeft: "-1.5rem" }}>
                  gPROMS
                </span>
              </button>
            </div>
            <div className="col-md-5">
              <button
                className="theme-btn invert-colors brick w-full fs-28"
                onClick={handleClick}
              >
                <img src={StarCCA} alt="gPROMS Image" style={{ width: '50px' }} />
                <span className="flex-1" style={{ marginLeft: '-1.5rem' }}>
                  Star-CCM+
                </span>
              </button>
            </div>
            <div className="col-md-5">
              <button className="theme-btn invert-colors brick w-full fs-28">
                <img
                  src={Synthia}
                  alt="gPROMS Image"
                  style={{ width: "50px" }}
                />
                <span className="flex-1" style={{ marginLeft: "-1.5rem" }}>
                  Synthia
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
