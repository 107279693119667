import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import logo from "../../assets/pg_logo_icon.jpg";
import MaterialIcon from "../../assets/dashboard/Material 1.jpg";
import AnalyticsIcon from "../../assets/dashboard/Analytical.jpg";
import InternalProcessesIcon from "../../assets/dashboard/internal-Processes.jpg";
import ExternalCompanyImage from "../../assets/dashboard/External.jpg";
import EquipmentModelImage from "../../assets/dashboard/equipment-models.png";
import AnalyticalImage from "../../assets/dashboard/Analytical.jpg";
import EquipmentLibrary from "../../assets/dashboard/equipment-library.png"
import ModelLibrary from "../../assets/dashboard/Models.png"
import DataWarehouseIcon from "../../assets/dashboard/Data Warehouse.png"

const Sidebar = ({ setCorsErrorModalOpen }: any) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    if (/material/g.test(window.location.href)) {
      setActiveTab("material");
    } else if (/internal-processes/g.test(window.location.href)) {
      setActiveTab("internal-processes");
    } else if (/equipments/g.test(window.location.href)) {
      setActiveTab("equipments");
    } else if (/models-and-simulation/g.test(window.location.href)) {
      setActiveTab("models-and-simulation");
    } else if (/analytical-labs/g.test(window.location.href)) {
      setActiveTab("analytical-labs");
    } else if (/external-companies/g.test(window.location.href)) {
      setActiveTab("external-companies");
    }
    else if (/data-warehouse/g.test(window.location.href)) {
      setActiveTab("data-warehouse");
    } else {
      setActiveTab("dashboard");
    }
  }, []);

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err: any) => (err.name === "AuthApiError" && !err.errorCode && err.xhr.message === "Failed to fetch");

  // const login = async () => history.push('/login');

  const logout = async () => {
    const basename = window.location.origin; // + history.createHref({ pathname: '/' });
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  console.log({activeTab});
  

  return (
    <aside className="sidebar">
      <header>
        <div className="side-head">
          <span className="img">
            <img
              src={logo}
              alt="Logo"
              className="img-fluid fav"
              height="860"
              width="2560"
            />
            {/* <img
              src={}
              alt="Logo"
              className="img-fluid"
              height="16"
              width="133"
            /> */}
          </span>
          <div className="text logo-text">
            <button
              type="button"
              title="Open"
              className="sidebar-toggler"
              onClick={() => {
                const body = document.querySelector("#root");
                if (body?.classList.contains("sidebar-open")) {
                  body?.classList.remove("sidebar-open");
                } else {
                  body?.classList.add("sidebar-open");
                }
              }}
            >
              <span className="bar" />
              <span className="bar m" />
              <span className="bar" />
            </button>
          </div>
        </div>
      </header>

      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            <li className={`item ${activeTab == "dashboard" ? "active" : ""}`}>
              <Link to="/" className="link">
                <i className="fa-solid fa-chart-simple" />
                <span className="text">Dashboard</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "material" ? "active" : ""}`}>
              <Link to="/materials" className="link" title="Materials">
                <img src={MaterialIcon} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text" style={{ marginLeft: "0.85rem" }}>Materials</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "equipments" ? "active" : ""}`}>
              <Link to="/equipments" className="link" title="Equipments">
                <img src={EquipmentLibrary} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text" style={{ marginLeft: "0.85rem" }}>Equipments</span>
              </Link>
            </li>
            <li className={`item ${activeTab === "internal-processes" ? "active" : ""}`}>
              <Link to="/internal-processes" className="link" title="Internal Processes">
                <img src={InternalProcessesIcon} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text" style={{ marginLeft: "0.85rem" }}>Internal processes</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "external-companies" ? "active" : ""}`}>
              <Link to="/external-companies" className="link" title="Equipments">
                <img src={ExternalCompanyImage} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text">External companies</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "models-and-simulation" ? "active" : ""}`}>
              <Link to="/models-and-simulation" className="link" title="Equipments">
                <img src={ModelLibrary} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text">Models</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "analytical-labs" ? "active" : ""}`}>
              <Link to="/analytical-labs" className="link" title="Equipments">
                <img src={AnalyticalImage} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text">Analytical Labs</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "data-warehouse" ? "active" : ""}`}>
              <Link to="/data-warehouse" className="link" title="Warehouse">
                <img src={DataWarehouseIcon} style={{ width: "20px", marginLeft: "-0.25rem" }} />
                <span className="text">Data Warehouse</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="bottom-content">
          <ul className="menu-links">
            {authState.isAuthenticated && (
              <li className="item">
                <button className="link" title="Logout" id="logout-button" onClick={logout}>
                  <i className="fa-solid fa-gear" />
                  <span className="text">Logout</span>
                </button>
              </li>
            )}
            {!authState.isPending && !authState.isAuthenticated && (
              <li className="item">
                <Link to="/login" className="link" title="Login">
                  <i className="fa-solid fa-gear" />
                  <span className="text">Login</span>
                </Link>
              </li>
            )}
            {/* <li className="item">
              <Link to="/" className="link" title="Logout">
                <i className="fa-solid fa-gear" />
                <span className="text">Logout</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
