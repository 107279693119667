import React, { useEffect, useRef, useState } from "react";
import useAlert from "../../../../hooks/useAlert";
import FileService from "../../../../services/fileService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { generateMaterialChains, saveMaterialChains } from "../../../../services/materialChains";
import CustomLoader from "../../../../components/common/CustomLoader";

export default function Form({ unchainedData, setUnchainedData, setSubmitFn }: {
  unchainedData: any,
  setUnchainedData: any,
  setSubmitFn: any
}) {
  const [unchainedImages, setUnchainedImages] = useState<any>([]);
  const [excelFile, setExcelFile] = useState("");
  const [savingImages, setSavingImages] = useState(false);
  const [savingExcelFile, setSavingExcelFile] = useState(false);
  const { errorAlert, successAlert } = useAlert();
  const unchainedImagesRef = useRef<any>();
  const excelInputRef = useRef<any>();
  const material = useSelector((state: RootState) => state.material);
  const materialId = material?.item?.id;
  const materialItem = material?.item;
  const btnRef = useRef<any>();

  const [unchainedImagesPayload, setUnchainedImagesPayload] = useState<any>(null);
  const [excelFilePayload, setExcelFilePayload] = useState<any>(null);

  const [saveDataPayload, setSaveDataPayload] = useState<any>(null);

  const [uploadingData, setUploadingData] = useState(false);

  useEffect(() => {
    function clickHandler() {
      if (btnRef.current)
        btnRef.current.click();
    }

    setSubmitFn(() => clickHandler);
  }, []);

  async function saveExcelFile() {
    if (!excelFile) {
      errorAlert("Please upload the excel file");
      return;
    }
    setSavingExcelFile(true);
    const res = await FileService.create("/files/upload", {
      id: materialId,
      file_for: "Material",

      files: [{
        category: "chainlab-csv",
        field_name: "file",
        // @ts-ignore
        key: excelFile.name,
        // @ts-ignore
        mimeType: "text/csv",
      }],
    });
    const { data: { body: { files } } } = res;
    const file = files[0];
    const fObj = {
      ...file,
      signedUrl: undefined,
    };
    setExcelFilePayload(fObj);
    // @ts-ignore
    const blob = new Blob([excelFile], { type: excelFile.type });
    try {
      await fetch(file.signedUrl, { method: "PUT", body: blob });
      // successAlert("Filet  uploaded successfully");
    } catch (e) {
      errorAlert("Oops! An error occurred");
    }
    setSavingExcelFile(false);
    return Promise.resolve(fObj);
  }

  async function uploadData() {
    const excelData = await saveExcelFile();
    if (savingImages) return;
    if (unchainedImages.length !== 24) {
      errorAlert("Please upload 24 images");
      return;
    }
    setSavingImages(true);
    let res = await FileService.create("/files/upload", {
      id: materialId,
      file_for: "Material",
      files: unchainedImages.map((file: any) => ({
        category: "chainlab",
        field_name: "file",
        key: file.name,
        mimeType: "image/png",
      })),
    });
    const { data: { body: { files } } } = res;
    const unchainedImgs = files.map((f: any) => ({ ...f, signedUrl: undefined }));
    setUnchainedImagesPayload(unchainedImgs);

    let payload = {
      uid: materialItem.uid,
      vendor_name: materialItem.vendor_name,
      material_name: materialItem.material_name,
      lot_number: materialItem.lot_number,
      material_id: materialId,
      files: unchainedImgs,
      csv: excelData,
    };

    await Promise.all(
      files.map((f: any, index: number) => {
        const file = unchainedImages[index];
        const blob = new Blob([file], { type: file.type });
        return fetch(f.signedUrl,
          {
            method: "PUT",
            body: blob,
          });
      }),
    );

    setSaveDataPayload(payload);
    res = await generateMaterialChains(payload);
    if (res.data.code === 200) {
      setUnchainedData(res.data.body);
      // res = await saveMaterialChains(payload);
    } else {
      errorAlert("Oops! Something went wrong");
    }
    setSavingImages(false);
    successAlert("Files uploaded successfully");
  }

  async function saveLabsData() {
    await saveMaterialChains(saveDataPayload);
  }

  return <div>
    <div className="form-group">
      {
        (savingExcelFile || savingImages) && <CustomLoader />
      }
      <div className="custom-upload">
        <label className="ip-wrap" htmlFor="unchained-images">
          <span className="form-label">Upload Images</span>
          <div className="custom-upload">
            <input
              data-cy="input-unchained-images"
              type="file" ref={unchainedImagesRef}
              name="unchained-images"
              id="unchained-images"
              multiple
              accept=".png, .jpeg, .jpg"
              max="24" hidden onChange={(e) => {
              // @ts-ignore
              if (e.target.files) {
                // @ts-ignore
                const totalFiles = e.target.files.length;
                if (totalFiles !== 24) {
                  errorAlert(`${totalFiles} images selected. Please select 24 images`);
                  setUnchainedImages([]);
                  return;
                } else {
                  // @ts-ignore
                  setUnchainedImages(
                    Array.from(e.target.files).map(file => ({
                      fileIndex: parseInt(file.name.split("__")[1].split(".png")[0]),
                      file,
                    })).sort((a, b) => a.fileIndex - b.fileIndex).map(({ file }) => file),
                  );
                }
              }
            }} />
            <button type="button" className="theme-ip" onClick={() => {
              const fileInput = document.querySelector("#unchained-images");
              // @ts-ignore
              fileInput.click();
            }}>
              <i className="fa-solid fa-paperclip" />
              <span className="text">No File Chosen</span>
              <span className="theme-btn sm light-btn">Choose</span>
            </button>
          </div>
        </label>
        <div className="uploaded-files" style={{ height: "150px" }}>
          {unchainedImages.map((img: any, index: number) => <div className="upfile" key={index}>
            <span onClick={() => {
            }}>
                    <i className={`fa-solid fa-file`} />
                  </span>
            <div className="text">
              <div className="name ">
                <div className="attachment-name">{img.name}</div>
              </div>
            </div>
          </div>)}
          {
            !(unchainedImages && unchainedImages.length) &&
            <span className="px-3 text-sm">Uploaded files will be visible here</span>
          }
        </div>
      </div>
    </div>
    <div className="form-group">
      <div className="custom-upload">
        <label className="ip-wrap" htmlFor="meaured-solubility-excel">
          <span className="form-label">Upload Excel File</span>
          <div className="custom-upload">
            <input
              data-cy="input-meaured-solubility-excel"
              ref={excelInputRef} type="file" name="meaured-solubility-excel" id="meaured-solubility-excel"
              accept=".xlsx, .xls, .csv, .xlxm, .xlsm"
              onChange={(e) => {
                if (e.target.files) {
                  // @ts-ignore
                  setExcelFile(e.target.files[0]);
                }
              }}
              hidden />
            <button type="button" className="theme-ip" onClick={() => {
              const fileInput = document.querySelector("#meaured-solubility-excel");
              // @ts-ignore
              fileInput.click();
            }}>
              <i className="fa-solid fa-paperclip" />
              <span className="text">No File Chosen</span>
              <span className="theme-btn sm light-btn">Choose</span>
            </button>
          </div>
        </label>
        <div className="uploaded-files" style={{ height: "80px" }}>
          {excelFile ? <div className="upfile">
            <span onClick={() => {
            }}>
                    <i className={`fa-solid fa-file`} />
                  </span>
            <div className="text">
              <div className="name ">
                {/* @ts-ignore */}
                <div className="attachment-name">{excelFile.name}</div>
              </div>
            </div>

            <span className="ml-auto full-flex">
                    <i className="fa-solid fa-xmark close block"
                       onClick={() => {
                         setExcelFile("");
                         // @ts-ignore
                         excelInputRef.current.value = "";
                       }} />
                  </span>
          </div> : <span className="px-3 text-sm">Uploaded file will be visible here</span>}
        </div>
      </div>
    </div>
    <button
      className="theme-btn mb-6 w-full btn-md"
      data-cy="show-unchianed-results"
      type="button"
      onClick={() => uploadData()}>
      Show Results
    </button>
    <button type="button" ref={btnRef} className="hidden" onClick={async () => {
      setSavingImages(true);
      await saveLabsData();
      setSavingImages(false);
    }}>Save
    </button>
  </div>;
}