export const apiRoutes = {
  //Materials
  MATERIALS: '/materials',
  NEW_MATERIAL: '/materials/new',
  FIND_MATERIAL: '/materials/find',
  SEARCH_MATERIAL: '/materials/search',
  CLONE_MATERIAL: '/materials/clone',
  DELETE_MATERIAL: '/materials/delete',
  SCREENING_MATERIAL: '/materials/list',
  EXPORT_MATERIAL: '/materials/export',
  SOLUBILITY_MATERIAL: '/materials/material-solubility',
  LIST_MATERIAL_NAMES_MATERIAL: '/materials/list-vendor-material-names',
  LIST_LOT_NUMBERS_MATERIAL:'/materials/list-vendor-material-lot-numbers',
  LIST_LIQUID_SOLVENTS:'/materials/solvents',
  DOWNLOAD_MATERIAL_AUDIT_TRAIL: '/materials/log-report-pdf',

  // CMAC Models
  SAVE_CMAC: "/cmac-model/save",
  UPDATE_CMAC: "/cmac-model/update",
  GET_CMAC: "/cmac-model/get",
  DELETE_CMAC: "/cmac-model/delete",
  CMAC_CALCULATE: '/cmac-model/calculate',

  //Equipments
  EQUIPMENTS: '/equipments',
  NEW_EQUIPMENT: '/equipments/new',
  FIND_EQUIPMENT: '/equipments/find',
  SEARCH_EQUIPMENT: '/equipments/search',
  CLONE_EQUIPMENT: '/equipments/clone',
  DELETE_EQUIPMENT: '/equipments/delete',
  SCREENING_EQUIPMENT: '/equipments/screening',
  MODELS_EQUIPMENT: '/equipments/models',
  EXPORT_EQUIPMENT: '/equipments/export',
  LIST_OWNERS_EQUIPMENT :'/equipments/list-equipment-owners',
  LIST_TAG_NUMBERS_EQUIPMENT:'/equipments/list-equipment-tag-numbers',
  DOWNLOAD_EQUIPMENT_AUDIT_TRAIL: '/equipments/log-report-pdf',

  //Simulations
  MODEL_3_SIMULATION: '/simulations/model4',
  GET_MODEL_3_SIMULATION_DATA: '/simulations/get-model-3-data',
  DELETE_SIMULATION: '/simulations/delete',
}