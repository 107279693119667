import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NUMBER_INPUT_MAXLENGTH } from "../../../utils";
import { limitDigits } from "../../../utils/common";

const Material = (props: any) => {
    const { reset, control } = useForm<any>({ defaultValues: props.initialData })

    useEffect(() => {
        reset(props.initialData)
    }, [props?.initialData?.material_name]);

    return (
      <>
          <div className="row">
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="abbreviation" title="abbreviation">
                          <span className="form-label">Abbreviation</span>
                          {!props.readable ? (
                            <Controller
                              name="abbreviation"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.abbreviation}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="brand_name" title="Brand Name">
                          <span className="form-label">Brand Name</span>
                          {!props.readable ? (
                            <Controller
                              name="brand_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.brand_name}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="synonyms" title="Synonyms">
                          <span className="form-label">Synonyms</span>
                          {!props.readable ? (
                            <Controller
                              name="synonyms"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled" style={{
                                whiteSpace: "nowrap",
                                overflowX: "auto",
                                overflowY: "hidden",
                                textOverflow: "unset",
                            }}>{control._formValues.synonyms}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="boiling_point" title="Boiling Point (C)">
                          <span className="form-label">Boiling Point (C)</span>
                          {!props.readable ? (
                            <Controller
                              name="boiling_point"
                              control={control}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.boiling_point}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="cas_number" title="CAS Number">
                          <span className="form-label">CAS Number</span>
                          {!props.readable ? (
                            <Controller
                              name="cas_number"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.cas_number}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="chemical_formula" title="Chemical Formula">
                          <span className="form-label">Chemical Formula</span>
                          {!props.readable ? (
                            <Controller
                              name="chemical_formula"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.chemical_formula}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="incompatibility" title="Incompatibility">
                          <span className="form-label">Incompatibility</span>
                          {!props.readable ? (
                            <Controller
                              name="incompatibility"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.incompatibility}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="shelf_life" title="Decomposition/Shelf-life (month)">
                          <span className="form-label">Decomposition/Shelf-life (month)</span>
                          {!props.readable ? (
                            <Controller
                              name="shelf_life"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.shelf_life}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="chemical_safety" title="Chemical Safety">
                          <span className="form-label">Chemical Safety</span>
                          <div className="input-wrap select-outer">
                              {!props.readable ? (
                                <Controller
                                  name="chemical_safety"
                                  control={control}
                                  render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                />
                              ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.chemical_safety}</p>
                              )}
                          </div>
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="melting_point" title="Melting Point (C)">
                          <span className="form-label">Melting Point (C)</span>
                          {!props.readable ? (
                            <Controller
                              name="melting_point"
                              control={control}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.melting_point}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="price" title="Price ($/g)">
                          <span className="form-label">Price ($/g)</span>
                          {!props.readable ? (
                            <Controller
                              name="price"
                              control={control}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.price}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="molecular_weight" title="Molecular Weight (g/mol)">
                          <span className="form-label">Molecular Weight (g/mol)</span>
                          {!props.readable ? (
                            <Controller
                              name="molecular_weight"
                              control={control}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.molecular_weight}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="purity" title="Purity (%)">
                            <span className="form-label">
                                Purity (%)
                            </span>
                          {!props.readable ? (
                            <Controller
                              name="purity"
                              control={control}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.purity}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="phase" title="Phase">
                          <span className="form-label">Phase</span>
                          <div className="input-wrap">
                              {!props.readable ? (
                                <Controller
                                  name="phase"
                                  control={control}
                                  render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                              ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.phase}</p>
                              )}
                          </div>
                      </label>
                  </div>
              </div>
              <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="smiles" title="SMILES">
                          <span className="form-label">SMILES</span>
                          {!props.readable ? (
                            <Controller
                              name="smiles"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled"
                               style={{
                                   whiteSpace: "nowrap",
                                   overflowX: "auto",
                                   overflowY: "hidden",
                                   textOverflow: "unset",
                               }}
                            >{control._formValues.smiles}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
                          <span className="form-label">Lot Number</span>
                          {!props.readable ? (
                            <Controller
                              name="lot_number"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.lot_number}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="manufacturing_number" title="Manufacturing Number">
                          <span className="form-label">Manufacturing Number</span>
                          {!props.readable ? (
                            <Controller
                              name="manufacturing_number"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.manufacturing_number}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="project_name" title="Project Name">
                          <span className="form-label">Project Name</span>
                          {!props.readable ? (
                            <Controller
                              name="project_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.project_name}</p>
                          )}
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label
                        className="ip-wrap"
                        htmlFor="regulatory_filing"
                      >
                            <span className="form-label">
                                Regulatory Filing Data
                            </span>
                          <div className="input-wrap">
                              {!props.readable ? (
                                <Controller
                                  name="regulatory_filing"
                                  control={control}
                                  render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                />
                              ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.regulatory_filing_data}</p>
                              )}
                          </div>
                      </label>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                      <label className="ip-wrap" htmlFor="process_name" title="Process Name">
                          <span className="form-label">Process Name</span>
                          {!props.readable ? (
                            <Controller
                              name="process_name"
                              control={control}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          ) : (
                            <p className="theme-ip pt-3 disabled">{control._formValues.process_name}</p>
                          )}
                      </label>
                  </div>
              </div>
          </div>
      </>
    );
};

export default Material;