import React from "react";
import { Controller } from "react-hook-form";
import { Input, NumberField } from "react-aria-components";

export default function NumberInput({ name, maxLength = 12 }: { name: string, maxLength?: number }) {
  return <Controller
    render={({ field: { value, onBlur, onChange, name, ref, disabled }, fieldState: { invalid } }) =>
      <NumberField className="input-wrap" value={value} name={name} onChange={onChange}
                   onBlur={onBlur}
                   validationBehavior="aria"
                   isInvalid={invalid}
      >
        <Input className="theme-ip" placeholder="0" ref={ref} maxLength={maxLength} data-cy={`input-${name}`} />
      </NumberField>
    }
    name={name} />;
}