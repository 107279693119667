import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "../../components/context/UserContext";
import { materialAction } from "../../redux/actions/materialActions";
import Layout from "../../components/layout";
import HelpModal from "../../components/modals/HelpModal";
import { Link } from "react-router-dom";
import RnDImage from "../../assets/internal-process-dashboard/rnd.png";
import GatewayImage from "../../assets/internal-process-dashboard/gateways.png";
import PilotImage from "../../assets/internal-process-dashboard/pilot.jpg";
import ReportImage from "../../assets/internal-process-dashboard/reports.png";
import LuminataImage from "../../assets/analytical-labs/luminata.png";
import MettlersImage from "../../assets/analytical-labs/mettler.jpg";
import iLabImage from "../../assets/analytical-labs/iLab.jpg";
import Technobis from "../../assets/analytical-labs/Technobis.jpeg";
import BlizMetrics from "../../assets/analytical-labs/blaze-matrics.jpeg";
import WaterImage from "../../assets/analytical-labs/waters.png";

export default function AnalyticalLabs() {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  const hasReadPermission =
    user?.permissions?.updated?.material?.hasRead ?? false;
  const hasWritePermission =
    user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  return (
    <Layout title="Analytical Lab">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Internal Processes"}
        content={""}
      />
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">Analytical Lab</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link
            to="#"
            title="Help"
            className="icon-btn alter"
            onClick={() => {
              setOpenHelp(true);
            }}
          >
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h" style={{}}>
        <div className="body">
          <div className="md-container-2">
            <div className="row justify-around btn-list">
              <div className="col-md-5">
                <a
                  href="https://www.acdlabs.com/login/"
                  className="theme-btn invert-colors w-full brick fs-28"
                  target="_blank"
                >
                  <span className="flex items-center w-full">
                    <div style={{ width: "35%" }}>
                      <img src={LuminataImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Luminata</span>
                  </span>
                </a>
              </div>
              <div className="col-md-5">
                <Link
                  to="/analytical-labs/mettler"
                  title="Mettler"
                  className="theme-btn invert-colors w-full brick fs-28"
                >
                  <span className="flex items-center w-full">
                    <div style={{ width: "38%" }}>
                      <img src={MettlersImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Mettler</span>
                  </span>
                </Link>
              </div>
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "38%" }}>
                      <img src={WaterImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Waters</span>
                  </span>
                </button>
              </div>
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "40%" }}>
                      <img src={iLabImage} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">iLabs</span>
                  </span>
                </button>
              </div>
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "40%" }}>
                      <img src={Technobis} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">Technobis</span>
                  </span>
                </button>
              </div>
              <div className="col-md-5">
                <button className="theme-btn invert-colors w-full brick fs-28">
                  <span className="flex items-center w-full">
                    <div style={{ width: "40%" }}>
                      <img src={BlizMetrics} style={{ width: "50px" }} />
                    </div>
                    <span className="flex-1 text-left">BlazeMetrics</span>
                  </span>
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
