import React from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useUserInitials from "../../hooks/useUserInitials";

const Header = ({ setCorsErrorModalOpen }: any) => {
  const { authState, oktaAuth } = useOktaAuth();

  const userInitials = useUserInitials(authState);
  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err: any) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";

  const logout = async () => {
    const basename = window.location.origin; // + history.createHref({ pathname: '/' });
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  return (
    <header className="admin-header">
      <div className="inner-container">
        <div className="admin-nav">
          <div className="toggler">
            <button
              type="button"
              title="Open"
              className="sidebar-toggler"
              onClick={() => {
                const body = document.querySelector("#root");
                if (body?.classList.contains("sidebar-open")) {
                  body?.classList.remove("sidebar-open");
                } else {
                  body?.classList.add("sidebar-open");
                }
              }}
            >
              <span className="bar" />
              <span className="bar m" />
              <span className="bar" />
            </button>
          </div>
          <div className="center-logo" />
          <div className="dropdown user-drop">
            <button
              type="button"
              title="user"
              className="dropdown-toggle"
              data-toggle="dropdown"
            >
              {userInitials}
            </button>
            <div className="dropdown-menu dropdown-menu-right theme-drop">
              <Link to="/" className="link" title="Dashboard">
                Dashboard
              </Link>
              <Link to="/profile" className="link" title="Profile">
                Profile
              </Link>
              <a
                className="link"
                data-cy="logout-button"
                title="Logout"
                onClick={logout}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
