function triggerDisableInput(target: any) {
  target?.dispatchEvent(
    new CustomEvent(
      "disable-input", {
        bubbles: true,
      },
    ),
  );
}

function triggerEnableInput(target: any) {
  target?.dispatchEvent(
    new CustomEvent(
      "enable-input", {
        bubbles: true,
      },
    ),
  );
}

export function onDisableInput(callback: any) {
  document.addEventListener("disable-input", callback);
}

export function clearDisable(callback: any) {
  document.removeEventListener("disable-input", callback);
}

export function onEnableInput(callback: any) {
  document.addEventListener("enable-input", callback);
}

export function clearEnable(callback: any) {
  document.removeEventListener("enable-input", callback);
}

export function disableInput() {
  const target = document.querySelector(".react-chatbot-kit-chat-input");
  if (target) {
    target.setAttribute("disabled", "true");
    triggerDisableInput(target);
  }
}

export function enableInput() {
  const target = document.querySelector(".react-chatbot-kit-chat-input");
  if (target) {
    target.removeAttribute("disabled");
    triggerEnableInput(target);
  }
}
