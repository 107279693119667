import React from "react";
import useUserInitials from "../../hooks/useUserInitials";
import { useOktaAuth } from "@okta/okta-react";

export default function UserAvatar() {
  const { authState } = useOktaAuth();
  const userInitials = useUserInitials(authState);
  return <div
    className="ml-4 h-12 w-12 radius-50 inline-flex justify-center items-center flex-shrink-0 bg-input text-color"
  >
    {userInitials}
  </div>;
}