// helpers
import MultiFileUpload from "../../common/MultiFileUpload";
import { useEffect } from "react";
import { setTitleNumberInput } from "../../../utils/common";

const Attachments = (props: any) => {
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Safety Attachments"
                        name="safetyAttachment"
                        readable={true}
                        attachments={props.attachments.chemicalSafety}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Molecular Structure"
                        name="molecularStructure"
                        readable={true}
                        attachments={props.attachments.molecularStructure}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="HPLC method-Attachment"
                        name="purityHPLC"
                        readable={true}
                        attachments={props.attachments.purityHPLC}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="SMILES"
                        name="smiles"
                        readable={true}
                        attachments={props.attachments.smiles}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="USP Monograph"
                        name="uspMonograph"
                        readable={true}
                        attachments={props.attachments.uspMonograph}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Others Documents"
                        name="othersDocuments"
                        readable={true}
                        attachments={props.attachments.othersDocuments}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Takeda Process"
                        name="takedaProcess"
                        readable={true}
                        attachments={props.attachments.takedaProcess}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
            </div>
        </>
    );
};

export default Attachments;