import { isEmpty, size } from "lodash";
import Papa from "papaparse";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import Xarrow, { useXarrow } from "react-xarrows";
import { Button, Image } from "semantic-ui-react";
import CrossIcon from "../../assets/process-automation/circle-cross.svg";
import { Icons } from "../../pages/process-automation/icons";
import { IIconProps } from "../../pages/process-automation/interface";
import Header from "./header";

const urlMapper: { [key: string]: string } = {
  equipment: "https://pro-pem.com/equipments/find",
  omega: "https://pro-pem.com/materials/find",
};
interface IProps {
  droppedItem: any;
  parsedCsvFiles: string[];
  setIsAnalyzing: (action: boolean) => void;
  setIsModalOpen: (action: boolean) => void;
  setDroppedItem: (items: any) => void;
  setParsedCsvFiles: (items: any) => void;
  setCsvFields: (items: string[]) => void;
}

const WorkBench: React.FC<IProps> = ({
  droppedItem,
  parsedCsvFiles,
  setIsAnalyzing,
  setIsModalOpen,
  setDroppedItem,
  setParsedCsvFiles,
  setCsvFields,
}) => {
  const [isChartRendered, setIsChartRendered] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null | any)[]>([]);
  const updateXarrow = useXarrow();
  const [containerWidth, setContainerWidth] = useState<number | undefined>(
    undefined
  );
  const containerRef = useRef<HTMLDivElement>(null);
  if (inputRefs.current.length !== droppedItem["csv"].length) {
    inputRefs.current = Array.from(
      { length: droppedItem["csv"].length },
      (_, i) => inputRefs.current[i] || React.createRef()
    );
  }
  const handleOnClickAnalyze = () => {
    if (isChartRendered) return;
    setIsAnalyzing(true);
    setTimeout(() => {
      setIsModalOpen(true);
      setIsAnalyzing(false);
      setIsChartRendered(true);
    }, 3000);
  };

  const handleFileChange = async (file: File, index: number) => {
    if (file.type === "text/csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const fields: string[] = [];
          results.meta.fields &&
            results.meta.fields.forEach((element) => {
              fields.push(element);
            });
          setCsvFields(fields);
          setParsedCsvFiles((prev: any) => {
            const items: any = [...prev];
            items[0] = results.data;
            return items;
          });
        },
      });
      setDroppedItem((prev: any) => {
        const prevCsvItem = { ...prev };
        const csvItems = [...prevCsvItem["csv"]];
        csvItems.splice(index, 1, {
          ...csvItems[index],
          fileName: file.name,
        });
        return { ...prevCsvItem, csv: csvItems };
      });
    }
  };

  const handleDrop = (item: IIconProps, keyName: string) => {
    console.log("item", item);
    setDroppedItem((prev: any) => {
      const prevItem = { ...prev };
      if (keyName === "csv" && item.type !== "automation") {
        const currentArray = prevItem["csv"] || [];
        currentArray.push({
          id: prevItem["csv"].length,
          type: item.type,
          icon: item.icon,
          title: item.title,
          url: urlMapper[item.type],
        });
        prevItem["csv"] = currentArray;
      } else if (keyName === "automation" && item.type === "automation") {
        prevItem.automation = item;
      }
      return prevItem;
    });
  };

  const [{ isOverCSV1 }, dropRefIcons] = useDrop({
    accept: "icon",
    drop: (item: IIconProps, monitor) => {
      console.log("item inside drop >>> ", item);
      if (size(droppedItem["csv"]) < 6) {
        handleDrop(item, "csv");
      }
    },
    collect: (monitor) => ({
      isOverCSV1: monitor.isOver(),
    }),
  });

  const [{ isOverAutomation }, dropRefAutomation] = useDrop({
    accept: "icon",
    drop: (item: IIconProps, monitor) => handleDrop(item, "automation"),
    collect: (monitor) => ({
      isOverAutomation: monitor.isOver(),
    }),
  });

  const handleCancel = (keyName: string, index: number) => {
    setDroppedItem((prev: any) => {
      const prevItem = { ...prev };
      setIsModalOpen(false);
      if (keyName === "automation") {
        prevItem[keyName] = {};
      } else {
        const prevCsvItem = prev.csv;
        prevCsvItem.splice(index, 1);
        setParsedCsvFiles([]);
      }
      setIsChartRendered(false);
      return prevItem;
    });
  };

  const handlOnCsvClick = (index: number) => {
    inputRefs.current[index].current?.click();
  };

  const hasFileName = droppedItem["csv"].some((item: any) =>
    item.hasOwnProperty("fileName")
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          setContainerWidth(entry.contentRect.width);
          updateXarrow();
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      className="automation-sections-wrapper work-panel relative height-full radius-6"
      ref={containerRef}
    >
      <Header text="Work Bench" />
      <div className="drop-panel work-bench--container" ref={dropRefIcons}>
        {!isEmpty(droppedItem?.csv) && (
          <Button
            className="reset-automation absolute z-10"
            onClick={() => {
              setDroppedItem({
                csv: [] as IIconProps[],
                automation: {} as IIconProps,
              });
              setIsChartRendered(false);
              setIsModalOpen(false);
              setParsedCsvFiles([]);
            }}
          >
            Reset
          </Button>
        )}
        <p className="text-center"></p>
        <div className="height-full flex">
          <div className="dropped-items file-icons flex justify-center items-center">
            {droppedItem["csv"].map((item: IIconProps, index: number) => (
              <label
                htmlFor="csv-select"
                className="relative"
                onClick={() => {
                  if (item.url) {
                    window.open(item.url);
                  }
                  handlOnCsvClick(index);
                }}
                title="Please select file for this icon"
              >
                <Icons
                  arrowId={`csv${index}`}
                  id={item.id}
                  key={item.id}
                  type={item.type}
                  icon={item.icon}
                  title={item.title}
                />
                <div className={`overlay ${!hasFileName && "cursor-pointer"}`}>
                  <Image
                    size="mini"
                    onClick={(event: any) => {
                      handleCancel("csv", index);
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    src={CrossIcon}
                  />
                </div>
                {item.fileName && <p>{item.fileName}</p>}
                {!hasFileName && item.type === "csv" && (
                  <input
                    id={`csv-select${index}`}
                    className="hidden"
                    accept=".csv"
                    onChange={(event) => {
                      if (event.target.files) {
                        handleFileChange(event.target.files[0], index);
                      }
                    }}
                    type="file"
                    ref={inputRefs.current[index]}
                  />
                )}
              </label>
            ))}
          </div>
          <div className="dropped-items flex justify-center relative items-center flex-col">
            <div
              id="dropbox"
              className="dropbox radius-6"
              style={{ opacity: isOverAutomation ? 0.5 : 1 }}
              ref={dropRefAutomation}
            >
              {!isEmpty(droppedItem.automation) ? (
                <Fragment>
                  <Icons
                    id={droppedItem.automation.id}
                    type={droppedItem.automation.type}
                    icon={droppedItem.automation.icon}
                    title={droppedItem.automation.title}
                    arrowId={"automation-arrow"}
                    className="relative z-1"
                  />
                  <div className="overlay overlay-automation">
                    <Image
                      size="mini"
                      onClick={(event: any) => {
                        handleCancel("automation", 0);
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      src={CrossIcon}
                    />
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </div>
            {!isEmpty(droppedItem.automation) && (
              <Button
                tit
                disabled={isEmpty(parsedCsvFiles)}
                className="play-btn relative z-10"
                onClick={handleOnClickAnalyze}
              >
                Analyze
              </Button>
            )}
          </div>
        </div>
      </div>
      {droppedItem["csv"].map((item: IIconProps, index: number) => (
        <Xarrow
          key={item.id}
          start={`csv${index}`}
          end="dropbox"
          color="gray"
          path="smooth"
        />
      ))}
    </div>
  );
};

export default WorkBench;
