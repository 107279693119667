import React, { FC } from 'react'

interface Props {
  isFixed?:boolean;
}

const CustomLoader:FC<Props> = ({isFixed = true}) => {
  return (
    <div className={`theme-loader show ${isFixed ? "fixed" : ""}`}>
    <div className="loader-outer">
      <div className="loader"></div>
    </div>
  </div>
  )
}

export default CustomLoader;