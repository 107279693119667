import React, { useState } from "react";
import Layout from "../../components/layout";
import HelpModal from "../../components/modals/HelpModal";
import { Link } from "react-router-dom";
import GatewayImage from "../../assets/internal-process-dashboard/gateways/gateway.png";
import BackButton from "../../components/common/BackButtonNonForm";

export default function Gateways({}) {
  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  return (
    <Layout title="Internal Processes - Gateways">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Internal Processes"}
        content={""} />
      <div className="sec-info control-head">
        <BackButton />
        <div className="head">
          <h1 className="head-lg">Internal Processes - Gateways</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link to="#" title="Help" className="icon-btn alter" onClick={() => {
            setOpenHelp(true);
          }}>
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h" style={{}}>
        <div className="body p-0">
          <img src={GatewayImage} alt="Gateways" />
        </div>
      </div>

    </Layout>
  );
}