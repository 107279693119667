import React from "react";
import { Icons } from "../../pages/process-automation/icons";

import Equipment from "../../assets/process-automation/equipment.svg";
import Process from "../../assets/process-automation/processes.svg";
import Csv from "../../assets/process-automation/csv-icon.svg";
import IC from "../../assets/process-automation/ic.svg";
import Json from "../../assets/process-automation/json.svg";
import Omega from "../../assets/process-automation/omega-icon.svg";
import Osi from "../../assets/process-automation/osi-icon.svg";
import Sql from "../../assets/process-automation/sql-icon.svg";
import Syn from "../../assets/process-automation/syn-icon.svg";
import XML from "../../assets/process-automation/xml.svg";
import Header from "./header";

const ICONS = [
  {
    id: 1,
    icon: <img src={Omega} alt="icon" />,
    type: "omega",
    title: "Materials",
  },
  {
    id: 2,
    icon: <img src={Equipment} alt="icon" />,
    type: "equipment",
    title: "Equipments",
  },
  {
    id: 3,
    icon: <img src={Process} alt="icon" />,
    type: "automation",
    title: "Process",
  },

  {
    id: 4,
    icon: <img src={Csv} alt="icon" />,
    type: "csv",
  },
  {
    id: 6,
    icon: <img src={XML} alt="icon" />,
    type: "xml",
  },
  {
    id: 7,
    icon: <img src={Sql} alt="icon" />,
    type: "sql",
  },
  {
    id: 8,
    icon: <img src={Json} alt="icon" />,
    type: "json",
  },
  {
    id: 9,
    icon: <img src={Osi} alt="icon" />,
    type: "osi",
  },
  {
    id: 10,
    icon: <img src={Syn} alt="icon" />,
    type: "syn",
  },
  {
    id: 11,
    icon: <img src={IC} alt="icon" />,
    type: "ic",
  },
];

const IconsWrapper: React.FC = () => {
  return (
    <div className="automation-sections-wrapper">
      <Header text="Components Library" />
      <div className="drag-panel invert-colors radius-6">
        <div className="icons">
          {ICONS.map((item, index) => (
            <Icons
              id={item.id}
              key={item.id}
              type={item.type}
              icon={item.icon}
              arrowId={index.toString()}
              title={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IconsWrapper;
