import React from "react";

interface IProps {
  text: string;
}

const Header: React.FC<IProps> = ({ text }) => {
  return (
    <div className="theme-btn invert-colors w-full brick fs-28">
      <h1 className="head-lg automation">{text}</h1>
    </div>
  );
};

export default Header;
