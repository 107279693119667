import { Controller, useForm, useFormContext } from "react-hook-form";

// helpers
import { NUMBER_INPUT_MAXLENGTH } from "../../../../../utils";
import { limitDigits } from "../../../../../utils/common";

const Gas = (props: any) => {
    const { control } = useForm({ defaultValues: props?.initialData?.gas ?? {} });
    const methods = useFormContext();
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="application">
                            <span className="form-label">Application</span>
                            {!props.readable ? (
                                <Controller
                                    name="gas.application"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.gas?.application ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.application}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="boiling_point">
                            <span className="form-label">Boiling Point (C)</span>
                            {!props.readable ? (
                            <Controller
                                name="gas.boiling_point"
                                control={methods?.control ?? control}
                                defaultValue={methods.control._formValues?.gas?.boiling_point ?? ""}
                                render={({ field }) => <input type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                            />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.boiling_point}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="density">
                            <span className="form-label">Density (g/ml)</span>
                            {!props.readable ? (
                            <Controller
                                name="gas.density"
                                control={methods?.control ?? control}
                                defaultValue={methods.control._formValues?.gas?.density ?? ""}
                                render={({ field }) => <input type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                            />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="heat_of_vaporization">
                            <span className="form-label">Heat of Vaporization (kJ/mol)</span>
                            {!props.readable ? (
                            <Controller
                                name="gas.heat_of_vaporization"
                                control={methods?.control ?? control}
                                defaultValue={methods.control._formValues?.gas?.heat_of_vaporization ?? ""}
                                render={({ field }) => <input type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                            />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.heat_of_vaporization}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="vapor_density">
                            <span className="form-label">Vapor Density (g/ml)</span>
                            {!props.readable ? (
                            <Controller
                                name="gas.vapor_density"
                                control={methods?.control ?? control}
                                defaultValue={methods.control._formValues?.gas?.vapor_density ?? ""}
                                render={({ field }) => <input type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                            />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.vapor_density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="viscosity">
                            <span className="form-label">Viscosity (cP)</span>
                            {!props.readable ? (
                            <Controller
                                name="gas.viscosity"
                                control={methods?.control ?? control}
                                defaultValue={methods.control._formValues?.gas?.viscosity ?? ""}
                                render={({ field }) => <input type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                            />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.viscosity}</p>
                            )}
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Gas;