import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const BackConfirmModal = (props: any) => {
    return (
        <Modal
            open={props.open}
            size="tiny"
            dimmer='blurring'
            onClose={() => props.setOpen(false)}
        >
            <Modal.Header align="center" className='border-none pb-0'>
                <div className='mt-8'>
                    <Icon name='warning sign' color="yellow" size="big" />
                </div>
                <Icon name="times circle outline" onClick={() => props.setOpen(false)} color="grey" className='close-icon'></Icon>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p className='head-lg text-center pb-0'>Please save your data first, otherwise, it will be lost?</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='border-none text-center bg-none mb-6'>
                <Button color="blue" title={props?.saveLabel??'Save & Back'} onClick={props?.saveMaterial}>
                    {props.saveLabel??'Save & Back'}
                </Button>
                <Button basic title={props?.dontSaveLabel??'Don`t Save & Back'} color="blue" onClick={props?.dontSave}>
                    {props.dontSaveLabel??'Don`t Save & Back'}
                </Button>
                <Button title='Cancel' onClick={() => props.setOpen(false)}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
};
export default BackConfirmModal;