import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useOktaAuth } from "@okta/okta-react";
import { setTitleNumberInput } from "../../utils/common";

// components
import Layout from "../../components/layout";
import HeaderRow from "../../components/layout/HeaderRow";

// modals
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import HelpModal from "../../components/modals/HelpModal";

// Tabs
import {
  Material, Gas, Liquid, SolubilityInformation,
  SpectralInformation, EnvironmentalData,
  General, Aeration, Compressibility, Densities,
  DrugInformation, FlowEnergy, Attachments,
  Hygroscopicity, Morphologi, Permeability,
  PSD, SEM, ShearCell, ThermalAnalysis,
} from "../../components/material/tabs";
import AuditTrail from "../../components/common/AuditTrail";

// context
import { useUser } from "../../components/context/UserContext";

// props
import { RootState } from "../../redux/store";
import { FilesProps } from "../../types";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// helpers
import {
  aerationInitialData,
  compressibilityInitialData,
  densityInitialData,
  drugInfoInitialData,
  environmentInitialData,
  flowEnergyInitialData,
  formatMaterialFiles,
  gasInitialData,
  generalSolidInitialData,
  hygroscopicityInitialData,
  initialMaterialData,
  liquidInitialData,
  morphologiInitialData,
  permeabilityInitialData,
  psdInitialData,
  semInitialData,
  shearCellInitialData,
  solubilityInitialData,
  thermalInitialData,
} from "../../utils/materialHelper";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { FindMaterialHelp } from "../../utils/helpContent";
import MaterialService from "../../services/materialService";
import LogService from "../../services/logService";
import FileService from "../../services/fileService";
import { apiRoutes } from "../../utils/apiRoutes";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import { downloadFileFromURL } from "../../utils/downloadFile";
//import { useFormContext } from "react-hook-form";
import CustomLoader from "../../components/common/CustomLoader";
import BackButton from "../../components/common/BackButtonNonForm";

interface filesByCategoryProps {
  solubility: FilesProps[] | [];
  spectralInformation: {
    atrIR: FilesProps[] | [];
    gcMS: FilesProps[] | [];
    ir: FilesProps[] | [];
    lcMS: FilesProps[] | [];
    msMS: FilesProps[] | [];
    nmr: FilesProps[] | [];
    raman: FilesProps[] | [];
  };
  environmental: FilesProps[] | [];
  attachments: {
    chemicalSafety: FilesProps[] | [];
    molecularStructure: FilesProps[] | [];
    purityHPLC: FilesProps[] | [];
    smiles: FilesProps[] | [];
    uspMonograph: FilesProps[] | [];
    othersDocuments: FilesProps[] | [];
    takedaProcess: FilesProps[] | [];
  };
}

const MaterialFind = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const methods = useFormContext();

  const material = useSelector((state: RootState) => state.material.item);

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // user context
  const { user } = useUser();

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [headerData, setHeaderData] = useState<any>();
  const [logs, setLogs] = useState<any>();
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [cloneId, setCloneId] = useState<any>();
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  const [deletableId, setDeletableId] = useState<any>(null);
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [materialDeleted, setMaterialDeleted] = useState<number>(0);
  const [downloadingFile, setDownloadingFile] = useState(false);

  const [filesByCategory, setFilesByCategory] = useState<filesByCategoryProps>({
    solubility: [],
    spectralInformation: {
      atrIR: [],
      gcMS: [],
      ir: [],
      lcMS: [],
      msMS: [],
      nmr: [],
      raman: [],
    },
    environmental: [],
    attachments: {
      chemicalSafety: [],
      molecularStructure: [],
      purityHPLC: [],
      smiles: [],
      uspMonograph: [],
      othersDocuments: [],
      takedaProcess: [],
    },
  });

  //form
  const handleHeaderData = (data: any) => {
    setLoading(data.loading);
    setHeaderData(data);
  };

  useEffect(() => {
    if (auth?.accessToken) {
      if (
        headerData?.material_name &&
        headerData.lot_number &&
        headerData?.lot_number
      ) {
        findMaterial();
      } else {
        setLogs([]);
      }
    }
  }, [
    headerData?.material_name,
    headerData?.vendor_name,
    headerData?.lot_number,
  ]);

  // useEffect(() => {
  //   setLogs([]);
  // }, [headerData]);

  useEffect(() => {
    // set default title on number input fields.
    console.log("on every re render")
    setTitleNumberInput();
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, []);
  const findMaterial = async () => {
    const payload = {
      // ...watchedFields,
      ...headerData,
      uid: `${auth?.claims?.uid}`,
    };
    // setLoading(true); // enable loading
    const res = await MaterialService.create(apiRoutes.FIND_MATERIAL, payload);
    // setLoading(true); // disable loading
    if (res?.data?.code === 200) {
      const result = res?.data;
      dispatch(materialAction(result.body));

      await getLogs(result?.body?.id);
      filesByCategory.solubility = [];
      filesByCategory.environmental = [];
      filesByCategory.spectralInformation.atrIR = [];
      filesByCategory.spectralInformation.gcMS = [];
      filesByCategory.spectralInformation.ir = [];
      filesByCategory.spectralInformation.lcMS = [];
      filesByCategory.spectralInformation.msMS = [];
      filesByCategory.spectralInformation.nmr = [];
      filesByCategory.spectralInformation.raman = [];
      // solubility files
      if (result?.body?.solubility?.attachments?.length) {
        filesByCategory.solubility =
          result?.body?.solubility?.attachments ?? [];
      }

      // environment files
      if (result?.body?.environment?.attachments?.length) {
        filesByCategory.environmental =
          result?.body?.environment?.attachments ?? [];
      }

      // spectral information files
      if (result?.body?.spectralInfo?.attachments) {
        for (var item in result?.body?.spectralInfo?.attachments) {
          switch (item) {
            case "atrIR":
              filesByCategory.spectralInformation.atrIR =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "gcMS":
              filesByCategory.spectralInformation.gcMS =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "ir":
              filesByCategory.spectralInformation.ir =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "lcMS":
              filesByCategory.spectralInformation.lcMS =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "msMS":
              filesByCategory.spectralInformation.msMS =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "nmr":
              filesByCategory.spectralInformation.nmr =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "raman":
              filesByCategory.spectralInformation.raman =
                result?.body?.spectralInfo?.attachments[item];
              break;
            default:
            // default
          }
        }
      }

      setFilesByCategory({ ...filesByCategory });
      if (
        result?.body?.attachments &&
        Array.isArray(result?.body?.attachments) &&
        result?.body?.attachments?.length
      ) {
        const attachments = formatMaterialFiles(result?.body?.attachments);
        for (var attachment in attachments) {
          switch (attachment) {
            case "Solubility Curve":
              filesByCategory.solubility = attachments[attachment];
              break;
            case "XX Report":
              filesByCategory.environmental = attachments[attachment];
              break;
            case "ATR-IR":
              filesByCategory.spectralInformation.atrIR =
                attachments[attachment];
              break;
            case "GC-MS":
              filesByCategory.spectralInformation.gcMS =
                attachments[attachment];
              break;
            case "IR":
              filesByCategory.spectralInformation.ir = attachments[attachment];
              break;
            case "LC-MS":
              filesByCategory.spectralInformation.lcMS =
                attachments[attachment];
              break;
            case "MS-MS":
              filesByCategory.spectralInformation.msMS =
                attachments[attachment];
              break;
            case "NMR":
              filesByCategory.spectralInformation.nmr = attachments[attachment];
              break;
            case "Raman":
              filesByCategory.spectralInformation.raman =
                attachments[attachment];
              break;
            case "Molecular Structure":
              filesByCategory.attachments.molecularStructure =
                attachments[attachment];
              break;
            case "USP Monograph":
              filesByCategory.attachments.uspMonograph =
                attachments[attachment];
              break;
            case "Chemical Safety":
              filesByCategory.attachments.chemicalSafety =
                attachments[attachment];
              break;
            case "HPLC Method":
              filesByCategory.attachments.purityHPLC = attachments[attachment];
              break;
            case "SMILES":
              filesByCategory.attachments.smiles = attachments[attachment];
              break;
            case "Process Attachment":
              filesByCategory.attachments.takedaProcess =
                attachments[attachment];
              break;
            case "Other Documents":
              filesByCategory.attachments.othersDocuments =
                attachments[attachment];
              break;
            case "Others":
              filesByCategory.attachments.othersDocuments =
                attachments[attachment];
              break;
            default:
            // default
          }
        }
        setFilesByCategory({ ...filesByCategory });
      }
      dispatch(materialAction(result.body));
    }
  };

  const getLogs = async (id: number) => {
    const payload = {
      entity_type: "material",
      entity_id: id,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await LogService.get("/logs/get", payload);
    if (res?.data?.code === 200) {
      setLogs(res?.data?.body ?? []);
    } else {
      setLogs([]);
    }
  };

  // Select file
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "atrIR":
        filesByCategory.spectralInformation.atrIR = [
          ...filesByCategory.spectralInformation.atrIR,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "ATR-IR",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      case "gcMS":
        filesByCategory.spectralInformation.gcMS = [
          ...filesByCategory.spectralInformation.gcMS,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "GC-MS",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      case "ir":
        filesByCategory.spectralInformation.ir = [
          ...filesByCategory.spectralInformation.ir,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "IR",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      case "lcMS":
        filesByCategory.spectralInformation.lcMS = [
          ...filesByCategory.spectralInformation.lcMS,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "LC-MS",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      case "msMS":
        filesByCategory.spectralInformation.msMS = [
          ...filesByCategory.spectralInformation.msMS,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "MS-MS",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      case "nmr":
        filesByCategory.spectralInformation.nmr = [
          ...filesByCategory.spectralInformation.nmr,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "NMR",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      case "raman":
        filesByCategory.spectralInformation.raman = [
          ...filesByCategory.spectralInformation.raman,
          ...Object.keys(e?.target?.files ?? []).map((key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: "Raman",
              file: e?.target?.files?.[key],
              error:
                parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
                UPLOAD_FILE_MAXLENGTH
                  ? true
                  : false,
            };
          }),
        ];
        break;
      default:
      // default
    }
    setFilesByCategory({ ...filesByCategory });
  };

  // Remove selected file
  const removeFile = (index: number, name: string) => {
    switch (name) {
      case "atrIR":
        filesByCategory.spectralInformation.atrIR =
          filesByCategory.spectralInformation.atrIR.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      case "gcMS":
        filesByCategory.spectralInformation.gcMS =
          filesByCategory.spectralInformation.gcMS.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      case "ir":
        filesByCategory.spectralInformation.ir =
          filesByCategory.spectralInformation.ir.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      case "lcMS":
        filesByCategory.spectralInformation.lcMS =
          filesByCategory.spectralInformation.lcMS.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      case "msMS":
        filesByCategory.spectralInformation.msMS =
          filesByCategory.spectralInformation.msMS.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      case "nmr":
        filesByCategory.spectralInformation.nmr =
          filesByCategory.spectralInformation.nmr.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      case "raman":
        filesByCategory.spectralInformation.raman =
          filesByCategory.spectralInformation.raman.filter(
            (file: any, i: number) => i !== index,
          );
        break;
      default:
      // default
    }
    setFilesByCategory({ ...filesByCategory });
  };

  const editMaterial = (data: any) => {
    if (data?.id) {
      history.push({
        pathname: `/materials/${data?.id}/update`,
        state: `find`,
      });
    } else {
      dispatch(alertOpenAction("Please select material first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const downloadFile = async (file: any) => {
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data?.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const cloneMaterial = (data: any) => {
    if (data?.id) {
      setOpenCloneModal(true);
      setCloneId(data?.id);
    } else {
      dispatch(alertOpenAction("Please select material first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  const deleteHandle = async () => {
    setOpen(false); // enable loading
    setLoading(true); // enable loading
    const payload = {
      id: deletableId,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await MaterialService.deleteMaterial(payload);
    if (res?.data?.code === 200) {
      // methods.setValue("material_name", ""); // clear material_name field
      // methods.setValue("lot_number", ""); // clear material_name field
      //dispatch(materialAction(null)); // clear material object

      setMaterialDeleted(Date.now());

    }

    setLoading(false);
  };

  const deleteMaterial = (data: any) => {
    if (data?.id) {
      setDeletableId(data.id);
      setConfirmMessage(`Are you sure you want to delete material “${data.material_name}” with lot number “${data.lot_number}”?`);
      setOpen(true);

    } else {
      dispatch(alertOpenAction("Please select material first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }

  };

  return (
    <>
      {downloadingFile && <CustomLoader />}
      <Layout title="Material Find">
        <ConfirmCloneModal
          open={openCloneModal}
          setOpen={setOpenCloneModal}
          type="Material"
          cloneId={cloneId}
          uid={auth?.claims?.uid ?? ""}
          operation="cloneFromFind"
        />
        <DeleteConfirmModal
          open={open}
          confirmMessage={confirmMessage}
          setOpen={setOpen}
          deleteHandle={deleteHandle}

        />
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={FindMaterialHelp.title}
          content={FindMaterialHelp.content}
        />
        <div className="sec-info control-head">
          <BackButton />
          <div className="head">
            <h1 className="head-lg">Find Material</h1>
          </div>
          <div className="right">
            {hasWritePermission && (
              <>
                {
                  <button
                    type="button"
                    className="icon-btn alter"
                    title="Delete"
                    onClick={() => deleteMaterial(material)}
                  >
                    <i className="fa-solid fa-trash" />
                  </button>
                }
                <button
                  type="button"
                  className="icon-btn alter"
                  title="Edit"
                  onClick={() => editMaterial(material)}
                >
                  <i className="fa-solid fa-pen" />
                </button>
                <button
                  type="button"
                  className="icon-btn alter"
                  title="Clone"
                  onClick={() => {
                    cloneMaterial(material);
                  }}
                >
                  <i className="fa-solid fa-clone" />
                </button>
              </>
            )}
            <Link to="/materials" title="Close" className="icon-btn alter">
              <i className="fa-solid fa-xmark" />
            </Link>
            <Link
              to="#"
              title="Help"
              className="icon-btn alter"
              onClick={() => {
                setOpenHelp(true);
              }}
            >
              <i className="fa-solid fa-question" />
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="theme-card">
              <div className="body">
                <form>
                  <HeaderRow headerData={handleHeaderData} user={user} materialDeleted={materialDeleted} />
                </form>
              </div>
            </div>
            {loading ? (
              <div className="theme-loader show">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div>
            ) : (
              <div className="theme-card">
                <div className="body">
                  <Tabs>
                    <div className="admin-tabs mx-card mb-6">
                      <TabList className="inner mb-3">
                        <Tab>
                          <div className="tab-link">Material</div>
                        </Tab>
                        <Tab>
                          <div className="tab-link">Solubility</div>
                        </Tab>
                        <Tab>
                          <div className="tab-link">Spectral Information</div>
                        </Tab>
                        <Tab>
                          <div className="tab-link">Environmental Data</div>
                        </Tab>
                        <Tab>
                          <div className="tab-link">Attachments</div>
                        </Tab>
                        <Tab>
                          <div className="tab-link">Audit Trail</div>
                        </Tab>
                      </TabList>
                    </div>
                    <TabPanel className={"find-tab-scroll"}>
                      <Material
                        readable={true}
                        initialData={initialMaterialData(material)}
                      />
                    </TabPanel>
                    <TabPanel className={"find-tab-scroll"}>
                      <SolubilityInformation
                        readable={true}
                        initialData={solubilityInitialData(material)}
                        attachments={filesByCategory.solubility}
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        downloadFile={(file: any) => downloadFile(file)}
                      />
                    </TabPanel>
                    <TabPanel className={"find-tab-scroll"}>
                      <SpectralInformation
                        readable={true}
                        attachments={filesByCategory.spectralInformation}
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        downloadFile={(file: any) => downloadFile(file)}
                      />
                    </TabPanel>
                    <TabPanel className={"find-tab-scroll"}>
                      <EnvironmentalData
                        readable={true}
                        initialData={environmentInitialData(material)}
                        attachments={filesByCategory.environmental}
                        handleFileChange={() => console.log("")}
                        removeFile={() => console.log("")}
                        downloadFile={(file: any) => downloadFile(file)}
                      />
                    </TabPanel>
                    <TabPanel className={"find-tab-scroll"}>
                      <Attachments
                        readable={true}
                        attachments={filesByCategory.attachments}
                        handleFileChange={() => console.log("")}
                        removeFile={() => console.log("")}
                        downloadFile={(file: any) => downloadFile(file)}
                      />
                    </TabPanel>
                    <TabPanel>
                      <div className="flex w-full items-center justify-center">
                        <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                          let res;
                          if (material?.id) {
                            setDownloadingFile(true);
                            res = await MaterialService.downloadAuditTrail({
                              material_id: material.id,
                            });
                            if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${material.id}.pdf`)
                              .then(() => setDownloadingFile(false)).catch(() => setDownloadingFile(false));
                            else setDownloadingFile(false);
                          }
                        }}>Generate Audit Report
                        </button>
                      </div>
                      <div className="find-tab-scroll-trail">
                        <AuditTrail items={logs} readable={true} />
                      </div>
                    </TabPanel>
                  </Tabs>

                  {/* divider */}
                  <div className={material?.phase == "Solid" ? "divider" : "hide"}></div>

                  {material?.phase == "Gas" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3 flex-wrap">
                          <Tab>
                            <div className={`tab-link `}>Gas Information</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Gas
                          initialData={gasInitialData(material)}
                          readable={true}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}

                  {material?.phase == "Liquid" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3 flex-wrap">
                          <Tab>
                            <div className={`tab-link `}>
                              Liquid Information
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Liquid
                          initialData={liquidInitialData(material)}
                          readable={true}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {material?.phase == "Solid" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3 flex-wrap">
                          <Tab>
                            <div className={`tab-link `}>#</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link `}>Aeration</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link `}>Compressibility</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Densities</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Drug Info</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Flow Energy</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Hygroscopicity</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Morphologi G3</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Permeability</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>PSD</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>SEM</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Shear Cell</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Thermal</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <General
                          readable={true}
                          initialData={generalSolidInitialData(material)}
                          attachments={
                            generalSolidInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Aeration
                          readable={true}
                          initialData={aerationInitialData(material)}
                          attachments={
                            aerationInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Compressibility
                          readable={true}
                          initialData={compressibilityInitialData(material)}
                          attachments={
                            compressibilityInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Densities
                          readable={true}
                          initialData={densityInitialData(material)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <DrugInformation
                          readable={true}
                          initialData={drugInfoInitialData(material)}
                          attachments={
                            drugInfoInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <FlowEnergy
                          readable={true}
                          initialData={flowEnergyInitialData(material)}
                          attachments={
                            flowEnergyInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Hygroscopicity
                          readable={true}
                          initialData={hygroscopicityInitialData(material)}
                          attachments={
                            hygroscopicityInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Morphologi
                          readable={true}
                          initialData={morphologiInitialData(material)}
                          attachments={
                            morphologiInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Permeability
                          readable={true}
                          initialData={permeabilityInitialData(material)}
                          attachments={
                            permeabilityInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <PSD
                          readable={true}
                          initialData={psdInitialData(material)}
                          attachments={
                            psdInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <SEM
                          readable={true}
                          initialData={semInitialData(material)}
                          attachments={semInitialData(material)?.attachments}
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <ShearCell
                          readable={true}
                          initialData={shearCellInitialData(material)}
                          attachments={
                            shearCellInitialData(material)?.attachments
                          }
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <ThermalAnalysis
                          readable={true}
                          initialData={thermalInitialData(material)}
                          attachments={thermalInitialData(material)?.attachments}
                          handleFileChange={() => console.log("")}
                          removeFile={() => console.log("")}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MaterialFind;
