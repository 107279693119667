import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "../../components/context/UserContext";
import { materialAction } from "../../redux/actions/materialActions";
import Layout from "../../components/layout";
import HelpModal from "../../components/modals/HelpModal";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../../components/common/BackButtonNonForm";

export default function Reaction({}) {
  const dispatch = useDispatch();
  const history = useHistory();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  const hasReadPermission = user?.permissions?.updated?.material?.hasRead ?? false;
  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  return (
    <Layout title="Reaction">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Internal Processes"}
        content={""} />
      <div className="sec-info control-head">
        <BackButton />
        <div className="head">
          <h1 className="head-lg">Reaction</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link to="#" title="Help" className="icon-btn alter" onClick={() => {
            setOpenHelp(true);
          }}>
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h" style={{}}>
        <div className="body">
          <div className="md-container-2">
            <div className="row justify-between btn-list">
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
