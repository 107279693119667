import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Layout from "../../../components/layout";

// modals
import HelpModal from "../../../components/modals/HelpModal";

// context
import { useUser } from "../../../components/context/UserContext";

// redux actions
import { materialAction } from "../../../redux/actions/materialActions";

// helpers
import FileService from "../../../services/fileService";
import CrystallizationService from "../../../services/crystallization";
import SolubilityScreening from "./SolubilityScreening";
import BackButton from "../../../components/common/BackButtonNonForm";
import Tool1 from "./Tool1";

const ModeOptions = {
  SolubilityScreening: "solubility-screening",
  Tool1: "tool-1",
};

export default function Crystallization() {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [mode, setMode] = useState("");

  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<any>(null);

  async function handleFormSubmit(data: any, file: any) {
    setLoading(true);
    try {
      const fileResponse = await FileService.create("/files/upload", {
        id: "",
        file_for: "Crystallization",
        files: [
          {
            key: file.name,
            mimeType: file.type,
          },
        ],
      });
      if (fileResponse.status === 200) {
        const fileRes = fileResponse.data.body.files[0];
        const blob = new Blob([file], { type: file.type });
        const uploadResponse = await fetch(fileRes.signedUrl, {
          method: "PUT",
          body: blob,
        });
        if (uploadResponse.status === 200) {
          const simulationResponse = await CrystallizationService.simulate({
            ...data,
            file_path: fileRes.location.split("amazonaws.com/").slice(-1)[0],
          });
          const resData = await simulationResponse.data.body;
          setResponseData(resData);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <Layout title="Internal Processes - Crystallization">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Internal Processes"}
        content={"Internal Processes"}
      />
      <div className="sec-info control-head">
        <BackButton />
        <div className="head">
          <h1 className="head-lg">Internal Processes - Crystallization</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link
            to="#"
            title="Help"
            className="icon-btn alter"
            onClick={() => {
              setOpenHelp(true);
            }}
          >
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h flex-col">
        <div className="w-full flex-1 px-8 py-6">
          {mode === ModeOptions.SolubilityScreening && (
            <SolubilityScreening
              handleFormSubmit={handleFormSubmit}
              responseData={responseData}
              setResponseData={setResponseData}
            />
          )}
          {mode === ModeOptions.Tool1 && <Tool1 />}
        </div>
        <div className="flex gap-16 crystallization-model-btn px-8 py-6">
          <button
            data-cy="to-solubility-screening"
            type="button"
            className="theme-btn mb-6 w-full btn-md"
            onClick={() => setMode(ModeOptions.SolubilityScreening)}
          >
            Solubility Screening
          </button>
          <button
            type="button"
            className="theme-btn mb-6 w-full btn-md"
            onClick={() => setMode(ModeOptions.Tool1)}
          >
            Tool 1
          </button>
          <button type="button" className="theme-btn mb-6 w-full btn-md">
            Tool 2
          </button>
          <button type="button" className="theme-btn mb-6 w-full btn-md">
            Tool 3
          </button>
        </div>
      </div>
      {loading ? (
        <div className="theme-loader show fixed">
          <div className="loader-outer">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
}
