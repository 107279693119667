import React from "react";

// @ts-ignore
const MessageParser = ({ children, actions }) => {
  //@ts-ignore
  const parse = (message) => {
    actions.askAmazonQ(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: actions,
        });
      })}
    </div>
  );
};

export default MessageParser;