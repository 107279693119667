import findEquipmentImgae from "../../../assets/materialHelp/find-equipment.png";


const FindEquipmentHelp = () => {
  return (
    <div className="instruction-modal">
      <div className="row ">
        <div className="col-md-4 ">
          <div><img src={findEquipmentImgae} alt="" /></div>
        </div>
        <div className="col-md-8">
          <p>
            This section allows user to find an equipment filtering by Equipment Type, Owner and Tag Number.
          </p>
          <p>
            We can see various properties of the Equipment like Location, Brand, Manufacturer Model Number,
            Material of Construction (wet) and Application and Scale
          </p>
          <a href="/public" target="_blank">Reference Link</a>
        </div>

      </div>

    </div>
  );
};

export default FindEquipmentHelp;
