import { Link } from "react-router-dom";

// components
import Layout from "../../components/layout";

// modals

// context

// redux actions

// helpers

// images

const externalVendors = [
  { label: "Select", value: "" },
  { label: "Cambrex", value: "Cambrex" },
  { label: "J.T. Baker", value: "J.T. Baker" },
  { label: "Koptec", value: "Koptec" },
  { label: "Nalas", value: "Nalas" },
  { label: "SigmaAldrich", value: "SigmaAldrich" },
];

const ExternalCompanies = () => {
  return (
    <Layout title="Materials">
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">Material Database</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h">
        <div className="body">
          <div className="md-container-2">
            <div className="row justify-between btn-list">
              <div className="col-md-5">
                <div className="input-wrap select-outer mt-4">
                  <select className="theme-ip">
                    {externalVendors.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-5">
                <button type="button" className="theme-btn w-full brick fs-28">
                  Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ExternalCompanies;
