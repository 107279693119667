// import addMaterialImage from '../../assets/addMaterial.png';
import addMaterialImage from '../../../assets/materialHelp/add-material-material-screen.png'


const AddMaterial = () => {
    return (
        <div className="instruction-modal">
            <div className="row ">
                <div className="col-md-4 ">
                    <div><img src={addMaterialImage} alt=""/></div>
                </div>
                <div className='col-md-8'>
                    <p>
                        This page hosts a form dedicated to the addition of new materials. Essential information
                        includes the Material Name, Vendor, Phase, and Lot Number.
                    </p>
                    <p>
                        Depending on the selected Phase value, additional properties can be entered by navigating
                        through the Phase Tab. This allows the users to input and organize pertinent details according
                        to the specific phase of the material.
                    </p>
                    <a href="/public" target="_blank">Reference Link</a>
                </div>

            </div>

        </div>
    )
}

export default AddMaterial