import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Layout from "../../components/layout";

// models
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { equipmentAction } from "../../redux/actions/equipmentActions";

// helpers
import { EquipmentDashboardHelp } from "../../utils/helpContent";

// images
import AddEquipmentImage from "../../assets/equipment-dashboard/add-material.png";
import EquipmentScreeningImage from "../../assets/equipment-dashboard/material-screening.png";
import EquipmentModelImage from "../../assets/equipment-dashboard/equipment-modeling.png";
import AddMaterialImage from "../../assets/material-dashboard/add-material.png";
import FindMaterialImage from "../../assets/material-dashboard/find-material.png";

const EquipmentIndex = () => {
  const dispatch = useDispatch();
  // user context
  const { user } = useUser();
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(equipmentAction(null));

    // Used in find page: fallback check
    if (localStorage?.getItem("page")) {
      localStorage?.removeItem("page");
    }
  }, []);

  const hasReadPermission =
    user?.permissions?.updated?.equipment?.hasRead ?? false;
  const hasWritePermission =
    user?.permissions?.updated?.equipment?.hasReadAndWrite ?? false;

  return (
    <Layout title="Equipment">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={EquipmentDashboardHelp.title}
        content={EquipmentDashboardHelp.content}
      />
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">Equipment Database</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link
            to="#"
            title="Help"
            className="icon-btn alter"
            onClick={() => {
              setOpenHelp(true);
            }}
          >
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h">
        <div className="body">
          <div className="md-container-2">
            {hasWritePermission && (
              <>
                <div className="row justify-around btn-list">
                  <div className="col-md-5">
                    <Link
                      to="/equipments/new"
                      className="theme-btn equipment-btn invert-colors w-full brick fs-28"
                      title="Add New Equipment"
                    >
                      <span className="flex items-center w-full">
                        <div style={{ width: "20%" }}>
                          <img
                            src={AddEquipmentImage}
                            style={{ width: "50px" }}
                          />
                        </div>
                        <span className="flex-1 text-left">
                          Add New Equipment
                        </span>
                      </span>
                    </Link>
                  </div>
                  {hasReadPermission && (
                    <div className="col-md-5">
                      <Link
                        to="/equipments/find"
                        className="theme-btn invert-colors w-full brick fs-28"
                        title="Find Equipment"
                        onClick={() => dispatch(equipmentAction(null))}
                      >
                        <span className="flex items-center w-full">
                          <div style={{ width: "20%" }}>
                            <img
                              src={FindMaterialImage}
                              style={{ width: "50px" }}
                            />
                          </div>
                          <span className="flex-1 text-left ml-4">
                            Find Equipment
                          </span>
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}

            {hasReadPermission && (
              <>
                {!hasWritePermission && (
                  <div className="row btn-list justify-center">
                    <div className="col-md-5">
                      <Link
                        to="/equipments/find"
                        className="theme-btn equipment-btn invert-colors w-full brick fs-28"
                        title="Find Equipment"
                        onClick={() => dispatch(equipmentAction(null))}
                      >
                        <span className="flex items-center w-full">
                          <div style={{ width: "20%" }}>
                            <img
                              src={AddMaterialImage}
                              style={{ width: "50px" }}
                            />
                          </div>
                          <span className="flex-1 text-left ml-4">
                            Find Equipment
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                )}
                <div className="row btn-list justify-around">
                  <div className="col-md-5">
                    <Link
                      to="/equipments/screening"
                      className="theme-btn equipment-btn invert-colors w-full brick fs-28"
                      title="Equipment Screening"
                    >
                      <span className="flex items-center w-full">
                        <div style={{ width: "20%" }}>
                          <img
                            src={EquipmentScreeningImage}
                            style={{ width: "50px" }}
                          />
                        </div>
                        <span className="flex-1 text-left">
                          Equipment Screening
                        </span>
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-5">
                    <Link
                      to="/equipments/models"
                      className="theme-btn equipment-btn invert-colors w-full brick fs-28 "
                      title="Equipment Models"
                    >
                      <span className="flex items-center w-full">
                        <div style={{ width: "20%" }}>
                          <img
                            src={EquipmentModelImage}
                            style={{ width: "50px" }}
                          />
                        </div>
                        <span className="flex-1 text-left">
                          Equipment Models
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EquipmentIndex;
