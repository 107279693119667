import { Controller } from 'react-hook-form'
import { capitalise } from '../../utils';

const SelectController = (props: any) => {
  return (
    <div className="col-lg-3 col-md-6">
      <div className="form-group ">
        <label
          className={`ip-wrap ${props?.required ? 'required' : ''}`}
          htmlFor={props?.name}
          title={props?.title}
        >
          <span className="form-label" >
            {props?.label}
          </span>

          <div className={`input-wrap ${props?.readOnly ? '' : 'select-outer'}`}>
            {!props.readOnly ? (
              <Controller
                name={props?.page ? `${props?.page}.${props?.name}` : props?.name}
                control={props.methods?.control}
                defaultValue=""
                render={({ field }) => (
                  <select className="theme-ip" {...field}>
                    <option value="" disabled selected>Select</option>
                    {props?.options?.map((item: any, index: number) => (
                      <option key={index} value={item?.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
              />
            ) : (
              <p className="theme-ip pt-3 disabled">
                {capitalise(props?.value.toString()) ?? ''}
              </p>
            )}

            {
              props?.errors ?
                props.errors[props?.name] && <p className="error">{props.errors[props?.name]?.message}</p>
                : ''
            }
          </div>
        </label>
      </div>
    </div>
  )
}

export default SelectController


//For edit use
{/* {equipment ?
<option value={equipment?.vendor_name} selected>{equipment?.vendor_name}</option>
: ''} 
EquipmentTypes?.filter((vendor: any, index: number) => !vendor.label.includes(equipment?.vendor_name)).map((vendor: any, index: number) => ( */}