import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

// components
import Layout from "../../../components/layout";
import BackButton from '../../../components/common/BackButton';
import CustomLoader from '../../../components/common/CustomLoader';

// modals
import CancelConfirmModal from '../../../components/modals/CancelFormModal';
import HelpModal from '../../../components/modals/HelpModal';

// tabs
import Gas from '../../../components/material/tabs/phase/gas';

// redux actions
import { materialAction } from '../../../redux/actions/materialActions';
import { alertCloseAction, alertOpenAction } from '../../../redux/actions';

// props
import { RootState } from '../../../redux/store';

// helpers
import { gasInitialData } from '../../../utils/materialHelper';
import { MaterialGasPhaseHelp } from '../../../utils/helpContent';
import { convertStringToNumber, setTitleNumberInput } from '../../../utils/common';
import MaterialService from '../../../services/materialService';

const MaterialGasPhase = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();

    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
    }, []);
    // auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';

    // Show data in input fields to update
    const material = useSelector((state: RootState) => state.material.item);
    // form
    const methods = useForm({ defaultValues: gasInitialData(material) });

    // states
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [openHelp, setOpenHelp] = useState<boolean>(false);
    const [materialData, setMaterialData] = useState<any>();

    useEffect(() => {
        methods.watch();
    });

    // To show warning when trying to exit form
    useEffect(() => {
        window.addEventListener('beforeunload', refreshData);
        return () => {
            window.removeEventListener('beforeunload', refreshData);
        };
    }, []);

    const refreshData = (e: any) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        if (!material) {
            history.push("/materials");
            return;
        }
        setMaterialData(material);
    }, [material]);


    const apiRequest = async () => {
        const payload = {
            uid: auth?.claims?.uid,
            ...materialData,
            id: materialData.id,
            // gas: methods?.control?._formValues?.gas
            gas: {
                ...methods?.control?._formValues?.gas,
                ...["boiling_point", "density", "heat_of_vaporization", "vapor_density", "viscosity"].reduce((e: any, t: any) => {
                    e[t] = convertStringToNumber(methods?.control?._formValues?.gas[t]);
                    return e;
                }, {}),
            }
        };
        const res = await MaterialService.update(payload);

        if (res?.status === 200) {
            dispatch(materialAction(res?.data?.body));
            methods.reset({
                material_name: methods.control._formValues?.material_name,
                vendor_name: methods.control._formValues?.vendor_name,
                lot_number: methods.control._formValues?.lot_number,
                gas: methods.control._formValues?.gas,
            }, { keepValues: true, keepIsValid: true });
            return true;
        }
        return false;
    }

    // Submit Form
    const onSubmit: SubmitHandler<any> = async (data) => {
        setLoading(true); // enable Loading
        const apiResponse = await apiRequest();
        setLoading(false); // disble Loading
        if (apiResponse) {
            dispatch(alertOpenAction(`Gas information ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
        } else {
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
    };

    // modal submit buttons
    const saveMaterial = async () => {
        if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
            setOpen(false);

            setLoading(true); // enable Loading
            const apiResponse = await apiRequest();
            setLoading(false); // disble Loading
            if (apiResponse) {
                dispatch(alertOpenAction(`Gas information ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
                params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
            } else {
                dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            }
            setTimeout(() => dispatch(alertCloseAction()));
        } else {
            setOpen(false);
            dispatch(alertOpenAction('No data updated to save.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    // modal cancel buttons
    const dontSave = () => {
        setOpen(false);
        params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
    }

    return (
        <Layout title="Material-Gas">
            {loading && <CustomLoader />}
            <FormProvider {...methods}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    methods.handleSubmit(onSubmit);
                }} noValidate>
                    <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={dontSave} />
                    <HelpModal
                        open={openHelp}
                        setOpen={setOpenHelp}
                        title={MaterialGasPhaseHelp.title}
                        content={MaterialGasPhaseHelp.content} />
                    <div className="sec-info control-head">
                        <div className="back">
                            <BackButton onSubmit={saveMaterial} formState={methods.formState} material={material} />
                        </div>
                        <div className="head">
                            <h1 className="head-lg">Gas Properties</h1>
                        </div>
                        <div className="right">
                            <button type="submit" title='Save' disabled={loading ? true : false} className={`icon-btn ${loading ? "disabled  " : ""}`} onClick={onSubmit}>
                                <i className="fa-regular fa-floppy-disk" />
                            </button>
                            <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
                                if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                                    setOpen(true);
                                    return;
                                }
                                params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
                            }}>
                                <i className="fa-solid fa-xmark" />
                            </Link>
                            <Link to="#" className="icon-btn alter" title='Help' onClick={() => { setOpenHelp(true) }}>
                                <i className="fa-solid fa-question" />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="theme-card">
                                <div className="body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <label className="ip-wrap" htmlFor="material_name" title="Material Name">
        <span className="form-label">Material Name</span>
                                                    <Controller
                                                        name="material_name"
                                                        control={methods?.control}
                                                        render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <label className="ip-wrap" htmlFor="vendor" title="Vendor">
        <span className="form-label">Vendor</span>
                                                    <div className="input-wrap">
                                                        <Controller
                                                            name="vendor_name"
                                                            control={methods?.control}
                                                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
        <span className="form-label">Lot Number</span>
                                                    <div className="input-wrap">
                                                        <Controller
                                                            name="lot_number"
                                                            control={methods?.control}
                                                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <Gas />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Layout>
    );
};

export default MaterialGasPhase;
