import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import _ from 'lodash';

// components
import Layout from "../../components/layout";
import BackButton from "../../components/common/BackButton";
import SolubilityInformation from "../../components/material/tabs/SolubilityInformation";

// modals
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";

// services
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction } from "../../redux/actions/materialActions";

// props
import { RootState } from "../../redux/store";

// helpers
import { checkDuplicateFiles, parseAttachments } from "../../utils/materialHelper";
import { convertStringToNumber, findFile, getUpdatedFiles, setTitleNumberInput } from "../../utils/common";
import { MaterialInnerSolubilityHelp } from "../../utils/helpContent";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";

const Solubility = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';

  // Show data in input fields to update
  const material = useSelector((state: RootState) => state?.material?.item);

  // states
  const [open, setOpen] = useState(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  // initialData
  const initialData: any = {
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    lot_number: material?.lot_number ?? null,
    solubility: {
      main_solvent: material?.solubility?.main_solvent?.toString() ?? '',
      solubility_in_main_solvent: material?.solubility?.solubility_in_main_solvent?.toString() ?? '',
      main_solvent_temperature: material?.solubility?.main_solvent_temperature?.toString() ?? '',
      main_solvent_reference: material?.solubility?.main_solvent_reference?.toString() ?? '',
      second_solvent: material?.solubility?.second_solvent?.toString() ?? '',
      solubility_in_second_solvent: material?.solubility?.solubility_in_second_solvent?.toString() ?? '',
      second_solvent_temperature: material?.solubility?.second_solvent_temperature?.toString() ?? '',
      second_solvent_reference: material?.solubility?.second_solvent_reference?.toString() ?? '',
      attachments: material?.solubility?.attachments ? parseAttachments(material?.solubility?.attachments) : [],
    }
  };

  const methods = useForm({ defaultValues: initialData});

  useEffect(() => {
    methods?.watch();
  });

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    methods.reset(methods.control._formValues);
    if (material?.solubility?.attachments?.length) {
      methods.setValue('solubility.attachments', parseAttachments(material.solubility.attachments));
    }
  }, [material]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      let displayNames = material?.solubility?.attachments ?? [];
      displayNames = displayNames?.map((attachment: any) => attachment.display_name);
      const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
        if (displayNames.includes(e?.target?.files?.[key].name)) {
          return true;
        }
      });
      if (isDuplicate) {
        dispatch(alertOpenAction('Same file already uploaded.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      methods.setValue('solubility.attachments', [...methods?.control?._formValues?.solubility?.attachments ?? [], ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: 'Solubility Curve',
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        })], { shouldDirty: true, shouldTouch: true });
    }
  }

  // Remove selected file
  const removeFile = (index: number, name: string) => {
    methods.setValue('solubility.attachments', methods.control._formValues.solubility.attachments.filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
  }

  const uploadFile = async (file: any) => {
    const newAttachment = await findFile(methods.control._formValues.solubility.attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: 'PUT',
          body: blob,
        })
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  }

  const uploadFileAPI = async (attachmentList: any[]) => {
    if (attachmentList?.length) {
      // file upload request
      const res = await FileService.create("/files/upload", {
        id: material?.id,
        file_for: 'Material',
        files: attachmentList.map((attachment: any) => ({
          category: attachment?.category,
          key: attachment.file?.name,
          mimeType: attachment?.file?.type,
        })),
      });

      if (res?.status === 200) {
        const filesResult = res?.data;
        // setLoading(true); // enable loading
        if (filesResult?.body?.files)
          return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
            .then(async (res) => {
              // update material object
              filesResult.body.files.map((file: any) => delete file.signedUrl);

              let solubilityData = {
                ...methods.control._formValues.solubility,
                ...[
                  'solubility_in_main_solvent', 'main_solvent_temperature', 'solubility_in_second_solvent', 'second_solvent_temperature',
                ].reduce((e: any, t: any) => {
                  e[t] = convertStringToNumber(methods?.control?._formValues?.solubility[t]);
                  return e;
                }, {}),
              }

              delete solubilityData.id;
              delete solubilityData.uid;
              delete solubilityData.lot_number;
              delete solubilityData.material_name;
              delete solubilityData.vendor_name;
              delete solubilityData.phase;
              delete solubilityData.page;
              delete solubilityData.sort_key;
              delete solubilityData.partition_key;

              const payload = {
                ...material,
                id: material?.id,
                uid: auth?.claims?.uid,
                solubility: solubilityData,
              }

              if (material?.solubility?.attachments?.length) {
                payload.solubility.attachments = [...material?.solubility?.attachments, ...filesResult.body.files];
              } else {
                payload.solubility.attachments = filesResult.body.files;
              };

              payload.solubility.attachments = _.uniqBy(payload.solubility.attachments, function (e: any) {
                return e.display_name;
              });
              payload.solubility.attachments = await getUpdatedFiles(payload.solubility.attachments, methods.control._formValues.solubility.attachments, !0);

              // material update request
              const materialResponse = await MaterialService.update(payload);
              if (materialResponse?.status === 200) {
                dispatch(materialAction(materialResponse?.data?.body));
                return true;
              }
              return false;
            })
            .catch(() => {
              return false;
            });
      }
      return false;
    }
    return true;
  }

  const apiRequest = async (newAttachments: any[]) => {
    if (newAttachments?.length) {
      return await uploadFileAPI(newAttachments);
    }

    let solubilityData = {
      ...methods.control._formValues.solubility,
      ...[
        'solubility_in_main_solvent', 'main_solvent_temperature', 'solubility_in_second_solvent', 'second_solvent_temperature',
      ].reduce((e: any, t: any) => {
        e[t] = convertStringToNumber(methods?.control?._formValues?.solubility[t]);
        return e;
      }, {}),
    }

    delete solubilityData.id;
    delete solubilityData.uid;
    delete solubilityData.lot_number;
    delete solubilityData.material_name;
    delete solubilityData.vendor_name;
    delete solubilityData.phase;
    delete solubilityData.page;
    delete solubilityData.sort_key;
    delete solubilityData.partition_key;

    const payload = {
      ...material,
      id: material?.id,
      uid: auth?.claims?.uid,
      solubility: {
        ...solubilityData,
        attachments: await getUpdatedFiles(material?.solubility?.attachments ?? [], methods.control._formValues?.solubility?.attachments ?? [], !1),
      }
    };

    // material update request
    const materialResponse = await MaterialService.update(payload);
    if (materialResponse?.status === 200) {
      dispatch(materialAction(materialResponse?.data?.body));
      return true;
    }
    return false;
  }

  const validateForm = async () => {
    const newAttachments = methods.control._formValues?.solubility?.attachments ?? [].filter((attachment: any) => !attachment.isUpload);
    if (newAttachments?.length) {
      const invalidateFiles = methods.control._formValues.solubility.attachments.find((attachment: any) => attachment.error);
      // Check invalid files.
      if (invalidateFiles) {
        dispatch(alertOpenAction('Max file size exceed. Please try again with valid files.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      // Check duplicate files in same category.
      const isDuplicate = await (await checkDuplicateFiles(methods.control._formValues?.solubility?.attachments))?.map((item: any) => item.isDuplicate).includes(true);
      if (isDuplicate) {
        dispatch(alertOpenAction('Please remove duplicate files.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }

      return true;
    }
    return true;
  }

  // Submit Form
  const onSubmit: SubmitHandler<any> = async (data) => {
    if (await validateForm()) {
      const newAttachments = methods.control._formValues?.solubility?.attachments ?? []?.filter((attachment: any) => !attachment.isUpload);
      setLoading(true); // enable Loading
      const apiResponse = await apiRequest(newAttachments);
      setLoading(false); // disble Loading
      if (apiResponse) {
        dispatch(alertOpenAction(`Solubility Data ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
      } else {
        dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Save and close modal button
  const saveMaterial = async () => {
    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
      setOpen(false);
      if (await validateForm()) {
        const newAttachments = methods.control._formValues?.solubility?.attachments?.filter((attachment: any) => !attachment.isUpload);
        setLoading(true); // enable Loading
        const apiResponse = await apiRequest(newAttachments);
        setLoading(false); // disble Loading
        if (apiResponse) {
          dispatch(alertOpenAction(`Solubility Data ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
          params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
        } else {
          dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction('No data updated to save.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  // Don't save and close modal button
  const dontSave = () => {
    setOpen(false);
    params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
  }

  return (
    <>
      <Layout title="Solid Properties - Solubility">
        <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={dontSave} />
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={MaterialInnerSolubilityHelp.title}
          content={MaterialInnerSolubilityHelp.content} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="sec-info control-head">
              <div className="back">
                <BackButton onSubmit={saveMaterial} formState={methods.formState} material={material} />
              </div>
              <div className="head">
                <h1 className="head-lg">Solubility Information</h1>
              </div>
              <div className="right">
                <button type="submit" title="Save" disabled={loading ? true : false} className={`icon-btn ${loading ? "disabled  " : ""}`}>
                  <i className="fa-regular fa-floppy-disk" />
                </button>
                <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
                  if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                    setOpen(true);
                    return;
                  }
                  params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
                }}>
                  <i className="fa-solid fa-xmark" />
                </Link>
                <Link to="#" className="icon-btn alter" title="Help" onClick={() => { setOpenHelp(true) }}>
                  <i className="fa-solid fa-question" />
                </Link>
              </div>
            </div>

            <div className="theme-card">
              <div className="body">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="materialName" title="Material Name">
                        <span className="form-label">Material Name</span>
                        <Controller
                          name="material_name"
                          control={methods.control}
                          render={({ field }) => <input disabled className="theme-ip" {...field} />}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="vendor" title="Vendor">
                        <span className="form-label">Vendor</span>
                        <div className="input-wrap">
                          <Controller
                            name="vendor_name"
                            control={methods.control}
                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
                        <span className="form-label">Lot Number</span>
                        <Controller
                          name="lot_number"
                          control={methods.control}
                          render={({ field }) => <input disabled className="theme-ip" {...field} />}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* divider */}
                <div className="divider"></div>

                <SolubilityInformation
                  attachments={methods?.control?._formValues?.solubility?.attachments ?? []}
                  handleFileChange={handleFileChange}
                  removeFile={removeFile}
                  onSubmit={onSubmit}
                  material={material}
                />
              </div>
              {loading ? <div className="theme-loader show fixed">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div> : ''}
            </div>
          </form>
        </FormProvider>
      </Layout>
    </>
  );
};
export default Solubility;