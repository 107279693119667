import addMaterialSolidPropertiesImage from '../../../assets/materialHelp/add-material-spectral.png'


const AddMaterial = () => {
    return (
        <div className="instruction-modal">
            <div className="row ">
                <div className="col-md-4 ">
                    <div><img src={addMaterialSolidPropertiesImage} alt=""/></div>
                </div>
                <div className='col-md-8'>
                    <p>
                        This section allows user to add various properties related to Material's Spectral Information
                    </p>
                    <a href="/public" target="_blank">Reference Link</a>
                </div>

            </div>

        </div>
    )
}

export default AddMaterial
