const Sortable = (props: any) => {
  let { title, fieldName, sortBy, sortOrder, manageSort, alignCenter = true } = props;

  const onClickHandle = (fName: string) => {
    manageSort(fName);
  };
  return (
    <div className={`flex w-full ${alignCenter ? "justify-center" : ""} items-center`}>
      <a href="javascript:void(0);" className="block sortable-link theme-txt fs-15"
         onClick={() => {
           onClickHandle(fieldName);
         }}
         style={{
           maxWidth: "200px",
         }}
      >{title} &nbsp;

      </a>
      {fieldName == sortBy ?
        sortOrder === "desc" ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>
        : ""}
    </div>
  );
};

export default Sortable;
