export const APP_ENV = process.env.APP_ENV || "development";
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || "{clientId}";
const ISSUER =
  process.env.REACT_APP_OKTA_ISSUER ||
  "https://{yourOktaDomain}.com/oauth2/default";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = "";
// const BASENAME = import.meta.env.BASE_URL || '';
// BASENAME includes trailing slash
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const USE_INTERACTION_CODE =
  process.env.USE_INTERACTION_CODE === "true" || false;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
    authParams: {
      responseType: ["token", "id_token"],
      responseMode: "fragment",
      prompt: "login",
    },
  },
  resourceServer: {
    messagesUrl: "http://localhost:8080/api/messages",
  },
  app: {
    basename: BASENAME,
  },
};
