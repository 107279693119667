import { createChatBotMessage } from "react-chatbot-kit";
import UserAvatar from "./UserAvatar";
import Header from "./Header";
import BotMessage from "./ChatBotMessage";

const config = {
  // @ts-ignore
  initialMessages: [createChatBotMessage(`Hello there! 👋 Welcome to our virtual space! I'm here to assist you with any questions or tasks you have. Whether you're seeking information, looking for support, or just want to chat, feel free to let me know how I can make your experience enjoyable and productive. Let's get started!`)],
  botName: "Generative AI Assistant",
  customComponents: {
    header: (props) => <Header {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />,
    botChatMessage: (props) => <BotMessage {...props} />,
  },
};

export default config;