import React, { useEffect, useMemo, useState } from "react";
import { disableInput, enableInput } from "./utils";

export default function BotMessage({ message, loader }: { message: string, loader: any }) {
  const memodMessage = useMemo(() => message, [message]);
  const [displayMessage, setDisplayMessage] = useState("");
  useEffect(() => {
    let curr = 0;
    disableInput();

    function typeWrite() {
      if (curr < memodMessage.length) {
        setDisplayMessage(memodMessage.slice(0, curr));
        curr++;
        setTimeout(typeWrite, 1);
      } else {
        enableInput();
      }
    }

    typeWrite();
  }, [memodMessage]);
  return <div
    className="react-chatbot-kit-chat-bot-message"
  >
    <span>{displayMessage}</span>
    <div
      className="react-chatbot-kit-chat-bot-message-arrow"
      style={{ borderRightColor: "" }}
    ></div>
  </div>;
}
