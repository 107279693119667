import equipmentModel from '../../../assets/materialHelp/equipment-models.png'


const EquipmentModelsHelp = () => {
    return (
        <div className="instruction-modal">
            <div className="row ">
                <div className="col-md-4 ">
                    <div><img src={equipmentModel} alt=""/></div>
                </div>
                <div className='col-md-8'>
                    <p>
                        Coming Soon...
                    </p>
                    <a href="/public" target="_blank">Reference Link</a>
                </div>

            </div>

        </div>
    )
}

export default EquipmentModelsHelp
