import {alertCloseAction, alertOpenAction} from "../redux/actions";

export async function downloadFileFromURL(fileUrl: any, dispatch: any, fileName:any) {
  const fileRes = await fetch(fileUrl);
  const blob = await fileRes.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  dispatch(alertOpenAction("File downloaded successfully.", "success"));
  setTimeout(() => dispatch(alertCloseAction()));
}