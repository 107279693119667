import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../components/layout";
import FilterSidebar from "../../components/equipment/FilterSidebar";
import CustomLoader from "../../components/common/CustomLoader";

// modals
import HelpModal from "../../components/modals/HelpModal";
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import ExportEquipmentModal from "../../components/modals/ExportEquipmentModal";

// context
import { useUser } from "../../components/context/UserContext";

// services
import EquipmentService from "../../services/equipmentService";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { equipmentAction } from "../../redux/actions/equipmentActions";

// helpers
import { capitalise } from "../../utils";
import Sortable from "../../components/solubility/Sortable";
import { sortByField } from "../../utils/common";

import { getLabelFromValue, labelObject } from "../../utils/equipmentHelper";
import { ListEquipmentHelp } from "../../utils/equipmentHelpContent";
import BackButton from "../../components/common/BackButtonNonForm";

const EquipmentScreening = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authState } = useOktaAuth();

  // user context
  const { user } = useUser();

  const auth: any = authState ? authState?.accessToken : "";

  const { control, setValue, watch } = useForm({
    defaultValues: {
      selectedRows: [],
      selectedEquipment: null,
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [openClone, setOpenClone] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [allEquipments, setAllEquipments] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isActive, setActive] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [ownerLabels, setOwnerLabels] = useState<any>();
  const [typeLabels, setTypeLabels] = useState<any>();
  const [selectAllRows, setSelectAllRows] = useState(false);

  const selectedRows: any = watch("selectedRows");
  const equipmentTypes = useMemo(() => allEquipments.filter((eq: any) => selectedRows.includes(eq.id)).map((eq: any) => eq.equipment_type), [selectedRows, allEquipments]);
  const [equipmentList, setEquipmentList] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  useEffect(() => {
    if (allEquipments && Array.isArray(allEquipments))
      setEquipmentList(allEquipments);
  }, [allEquipments]);

  function manageSort(newSortBy: string) {
    let newSortOrder: "asc" | "desc" = "desc";
    if (newSortBy == sortBy) {
      newSortOrder = sortOrder == "asc" ? "desc" : "asc";
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setEquipmentList(sortByField(equipmentList, newSortBy, newSortOrder));
  }


  useEffect(() => {
    {
      auth?.claims?.uid && getAllEquipments();
    }

  }, [auth?.claims?.uid]);

  useEffect(() => {
    if (filters) searchEquipments();
  }, [filters]);

  const handleRowSelect = (rowId: any) => {
    const newSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id: any) => id !== rowId)
      : [...selectedRows, rowId];
    setValue("selectedRows", newSelectedRows);
  };

  const handleExpand = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const handleFilters = (data: any) => {
    setFilters(data);
  };

  function handleReset() {
    setValue("selectedRows", []);
  }

  const getAllEquipments = async () => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      //   LastEvaluatedKey: lastEvaluatedKey ?? '',
    };
    setLoading(true); // enable loading
    const res = await EquipmentService.getAll(payload);
    setLoading(false); // disable loading
    if (res?.data?.code === 200) {
      const result = res?.data?.body;
      if (result?.Items) {
        //getting labels for values to display
        let OwnersArr = getLabelFromValue(result.Items, "owners");
        let TypeArr = getLabelFromValue(result.Items, "type");
        setOwnerLabels(labelObject(OwnersArr, "owners"));
        setTypeLabels(labelObject(TypeArr, "type"));

        setAllEquipments(result.Items);
      }
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const searchEquipments = async () => {
    // setValue('selectedRows', []);
    setLoading(true); // enable loading
    const payload = {
      uid: `${auth?.claims?.uid}`,
      // LastEvaluatedKey: lastEvaluatedKey ?? '',
      ...filters,
    };

    const res = await EquipmentService.getAll(payload);
    setLoading(false); // disable loading
    if (res?.data?.code === 200) {
      const result = res?.data?.body;
      if (result?.Items) {
        //getting labels for values to display
        let OwnersArr = getLabelFromValue(result.Items, "owners");
        let TypeArr = getLabelFromValue(result.Items, "type");
        setOwnerLabels(labelObject(OwnersArr, "owners"));
        setTypeLabels(labelObject(TypeArr, "type"));

        setAllEquipments(result.Items);
      } else {
        setAllEquipments([]);
        setLoading(false);
      }
    } else {
      setLoading(false);
      dispatch(alertOpenAction("Oops ! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };


  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    const allRowIds = allEquipments?.map((row: any) => row.id);
    setSelectAllRows(checked);
    setValue("selectedRows", checked ? allRowIds : []);
  };

  const handleExportModal = () => {
    selectedRows?.length ? setOpen(true) : dispatch(alertOpenAction("Please select rows you want to export.", "error"));
    setTimeout(() => dispatch(alertCloseAction()));
  };

  const editEquipment = (data: any) => {
    dispatch(equipmentAction(null));
    history.push({
      pathname: `/equipments/${data?.id}/update`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  const toggleClass = () => {
    setActive(!isActive);
    const filterButton = document.querySelector(".filter-responsive") as HTMLButtonElement;
    if (filterButton && filterButton.classList.contains("alter")) {
      filterButton.classList.remove("alter");
    } else {
      filterButton.classList.add("alter");
    }
  };

  const viewEquipment = (data: any) => {
    dispatch(equipmentAction(null));
    history.push({
      pathname: `/equipments/${data?.id}/view`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  const handleEquipmentClone = (item: any) => {
    setOpenClone(true);
    setValue("selectedEquipment", item);
  };

  const hasWritePermission = user?.permissions?.updated?.equipment?.hasReadAndWrite ?? false;

  return (
    <Layout title="Equipment Screening">
      <ExportEquipmentModal
        equipmentTypes={equipmentTypes}
        open={open}
        setOpen={setOpen}
        selectedRows={selectedRows}
        selectAllChecked={selectAllRows}
        filters={filters}
      />
      <ConfirmCloneModal
        open={openClone}
        setOpen={setOpenClone}
        type="Equipment"
        cloneId={control._formValues?.selectedEquipment?.id ?? null}
        uid={auth?.claims?.uid ?? null}
        operation="cloneFromList"
      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={ListEquipmentHelp.title}
        content={ListEquipmentHelp.content} />
      <div className="sec-info control-head">
        <BackButton />
        <div className="head">
          <h1 className="head-lg">All Equipments Screening</h1>
        </div>
        <div className="right">
          <Link to="#" title="Export" className="icon-btn" onClick={() => handleExportModal()}>
            <i className="fa-solid fa-file-export"></i>
          </Link>
          <Link to="/equipments" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link to="#" title="Help" className="icon-btn alter" onClick={() => {
            setOpenHelp(true);
          }}>
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>

      <div className="theme-card sm filter-card">
        <div className="body p-0">
          <div className="row sm-row row-responsive">
            <div className={isActive ? "left click-block" : "left"}>
              <FilterSidebar filters={handleFilters} user={user} handleReset={handleReset} />
            </div>
            {loading ? <CustomLoader /> :
              <div className={`right ${(equipmentList.length) ? "" : "full-flex"}`}>
                {!(equipmentList.length) ?
                  <div>
                    <h1>No data Found !</h1>
                  </div> :
                  <div className="table-outer">
                    <div className="table-responsive theme-table">
                      <table className="table">
                        <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={selectedRows.length === allEquipments?.length}
                            />
                          </th>
                          <th className="w-1"></th>
                          <th>
                            <Sortable
                              title="Equipment Type"
                              fieldName="equipment_type"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Equipment Owner"
                              fieldName="equipment_owner"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Equipment Tag Number"
                              fieldName="tag_number"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Location"
                              fieldName="location"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Application & Scale"
                              fieldName="application_and_scale"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th className="w-10">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {equipmentList?.map((equipment: any, index: any) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={() => handleRowSelect(equipment?.id)}
                                  checked={selectedRows.includes(equipment?.id)}
                                />
                              </td>
                              <td className="w-1 expand-cell">
                                <button
                                  type="button"
                                  className="icon-btn sm plain"
                                  onClick={() => handleExpand(index)}
                                >
                                  <i
                                    className={`fa-solid fa-chevron-${expandedRows.includes(index) ? "down" : "right"}`}
                                  />
                                </button>
                              </td>
                              <td className="f-heavy">{typeLabels[equipment?.equipment_type] ?? "N/A"}</td>
                              {/* <td>{ownerLabels[equipment?.equipment_owner] ?? 'N/A'}</td> */}
                              <td>{equipment?.equipment_owner ?? "N/A"}</td>
                              <td>{equipment?.tag_number ?? "N/A"}</td>
                              <td>{equipment?.location ?? "N/A"}</td>
                              <td>{equipment?.application_and_scale ? capitalise(equipment?.application_and_scale) : "N/A"}</td>
                              <td className="w-10">
                                <div className="tb-actions">
                                  {hasWritePermission && (
                                    <button type="button" className="icon-btn sm plain" title="Edit" onClick={() => {
                                      editEquipment(equipment);
                                    }}>
                                      <i className="fa-solid fa-pen" />
                                    </button>
                                  )}
                                  <button type="button" className="icon-btn sm plain" title="View" onClick={() => {
                                    viewEquipment(equipment);
                                  }}>
                                    <i className="fa-solid fa-eye" />
                                  </button>
                                  {hasWritePermission && (
                                    <button type="button" className="icon-btn sm plain" title="Clone" onClick={() => {
                                      handleEquipmentClone(equipment);
                                    }}>
                                      <i className="fa-solid fa-clone" />
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                            {expandedRows.includes(index) && (
                              <tr className="child-data">
                                <td colSpan={10}>
                                  <table className="sm-table">
                                    <tbody>
                                    <tr>
                                      <td className="f-heavy w-20 ">Brand:</td>
                                      <td>{equipment.brand ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Manufacturer Model Number</td>
                                      <td>{equipment?.manufacturer_model_number ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Material of Construction</td>
                                      <td>{equipment.material_of_construction ?? "N/A"}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default EquipmentScreening;