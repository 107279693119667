import addMaterialImage from '../../../assets/materialHelp/material-dashboard.png'

const MaterialDashboardHelpContent = () => {
    return (
        <div className="row">
            <div className="col-md-4">
                <div><img src={addMaterialImage} alt=""/></div>
            </div>
            <div className='col-md-8'>
                <p>
                    Welcome to the Material Dashboard, a sophisticated platform designed for streamlined material
                    management. At the core of this dashboard are four essential buttons, each serving a distinct
                    purpose in the handling of materials.
                </p>
                <p>
                    The Add New Material button stands as a gateway to seamlessly incorporate novel materials into the
                    system, ensuring that your material inventory remains dynamic and up-to-date.
                </p>
                <p>
                    The Find Material feature acts as a robust search tool, enabling users to quickly
                    locate specific materials within the extensive database, fostering efficiency in material retrieval.
                </p>
                <p>
                    For precise material selection, the Material Screening function takes center stage, allowing users
                    to filter materials based on specified properties. This powerful tool simplifies the process of
                    narrowing down choices, catering to diverse material requirements with ease.
                </p>
                <p>
                    Moreover, the Solubility feature adds a layer of sophistication by providing the
                    capability to calculate the solubility of materials.
                </p>

            </div>
        </div>
    )
}

export default MaterialDashboardHelpContent
