import React, { useCallback, useEffect, useState } from "react";
import ReactChatbot from "react-chatbot-kit";
import config from "./config";
import MessageParser from "./MessageProvider";
import ActionProvider from "./ActionProvider";
import { useOktaAuth } from "@okta/okta-react";
import { clearDisable, clearEnable, onDisableInput, onEnableInput } from "./utils";

export default function ChatBot() {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState && authState.isAuthenticated;
  const [showChatBot, setShowBot] = useState(false);
  const closeChatBot = useCallback(() => {
    setShowBot(false);
  }, [setShowBot]);
  const [placeholderText, setPlaceholderText] = useState("Loading ...");
  // Other text: "Write your message here"

  useEffect(() => {
    const handle = () => closeChatBot();
    document.addEventListener("close-chatbot", handle);
    return () => {
      document.removeEventListener("close-chatbot", handle);
    };
  }, [closeChatBot]);

  useEffect(() => {
    function handleDisable() {
      setPlaceholderText("Loading ...");
    }

    function handleEnable() {
      setPlaceholderText("Write your message here");
    }

    onDisableInput(handleDisable);
    onEnableInput(handleEnable);

    return () => {
      clearDisable(handleDisable);
      clearEnable(handleEnable);
    };

  }, [setPlaceholderText]);

  return (
    <>
      {
        isAuthenticated &&
        <>
          <div className="chatbot-wrapper" style={{
            display: showChatBot ? "block" : "none",
          }}>
            <ReactChatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              validator={(message: string) => message.trim().length !== 0}
              placeholderText={placeholderText}
            />
          </div>
          <button type="button" className="chatbot-trigger" onClick={() => setShowBot(s => !s)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="24" viewBox="0 0 24 24" width="24">
              <g stroke="white" strokeLinecap="round" strokeLinejoin="round">
                <path
                  d="m8.5 19h-.5c-4 0-6-1-6-6v-5c0-4 2-6 6-6h8c4 0 6 2 6 6v5c0 4-2 6-6 6h-.5c-.31 0-.61.15-.8.4l-1.5 2c-.66.88-1.74.88-2.4 0l-1.5-2c-.16-.22-.53-.4-.8-.4z"
                  strokeMiterlimit="10" strokeWidth="1.5" />
                <g strokeWidth="2">
                  <path d="m15.9965 11h.0089" />
                  <path d="m11.9955 11h.009" />
                  <path d="m7.99451 11h.00898" />
                </g>
              </g>
            </svg>
          </button>
        </>
      }
    </>
  );
};