import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useFormContext, useWatch } from "react-hook-form"; // Optional Theme applied to the grid

// TODO: Find a way to do in place editing

export default function DataGrid({ setOnReset }: { setOnReset: any }) {
  // Row Data: The data to be displayed.
  const [zeroRow, setZeroRow] = useState<number>(0);
  const rowData = useWatch({ name: "sheet_data" });
  const { setValue, getValues } = useFormContext();

  function addRows(newRows: any[]) {
    const currentRows = getValues("sheet_data");
    setValue(
      "sheet_data",
      [
        ...currentRows.slice(0, zeroRow),
        ...newRows,
        ...currentRows.slice(zeroRow),
      ].slice(0, 100),
      // @ts-ignore
      setZeroRow(z => Math.min(z + newRows.length, 100)),
    );
  }


  function setRowData(newRows: any []) {
    setValue("sheet_data", newRows);
  }

  useEffect(() => {
    function reset() {
      setZeroRow(0);
    }

    setOnReset(() => reset);
  }, [setZeroRow]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState<any>([
    { field: "one", editable: true, flex: 1, sortable: false, headerName: "X1 (%)" },
    { field: "two", editable: true, flex: 1, sortable: false, headerName: "TSat (C)" },
    { field: "three", editable: true, flex: 1, sortable: false, headerName: "Antisolvent (g/L solvent)" },
  ]);
  const dataGridRef = useRef<any>();

  useEffect(() => {

    async function listener(event: any) {
      // Paste the text from the clipboard into the text input
      // setText(event.clipboardData.getData("text/plain"));
      // TODO: Find a way to limit pasting only to dataGridRef.current element
      // if (!dataGridRef.current.contains(event.target))
      //   return;
      const data = await navigator.clipboard.readText();
      // @ts-ignore
      const clipboardData = data.split("\n");
      const dataRows: any[] = [];
      clipboardData.forEach((d) => {
        // @ts-ignore
        const obj: any = {};
        const strings = d.split("\t");
        if (!(strings[0] && strings[1] && strings[2]))
          return;
        obj["one"] = Number.parseFloat(strings[0]);
        obj["two"] = Number.parseFloat(strings[1]);
        obj["three"] = Number.parseFloat(strings[2]);
        dataRows.push(obj);
      });
      addRows(dataRows);
    }

    document.addEventListener("paste", listener);
    return () => document.removeEventListener("paste", listener);
  }, [setRowData]);

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 500 }} // the grid will fill the size of the parent container
      ref={dataGridRef}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        // @ts-ingore
        onCellValueChanged={(event) => {
          const oldData = getValues("sheet_data");
          const newData = oldData.map(
            (d: any, index: number) => index === event.rowIndex ? event.data : d,
          );
          // @ts-ignore
          setZeroRow(event.rowIndex + 1);
          setRowData(newData);
        }}
      />
    </div>
  );
}
