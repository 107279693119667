import {
  distillationTextFields,
  equipmentTextFields, evaporatorTextFields, filtrationTextFields, mixerTextFields,
  reactorSelectFields,
  vesselReactorTextFields,
  vesselSelectFields,
} from "../../utils/equipmentData";

function hasEmptyValue(val: any) {
  if (val === null || val === undefined)
    return true;
  if (typeof val === "object") {
    return Array.from(Object.keys(val)).length === 0;
  } else if (Array.isArray(val)) {
    return val.length === 0;
  } else {
    return val === "";
  }
}

export function cleanPayload(payload: any, isCreate = false) {
  const equipmentTypes = ["vessel", "reactor", "distillation", "filtration", "mixer", "evaporator"];

  const genericFields = [
    // General Fields
    "equipment_type", "equipment_owner", "tag_number",
    ...equipmentTextFields.map(({ value }) => value),
    "application_and_scale",
  ];

  const attachmentsCommon = ["drawing", "manufacturer_manual", "sop", "service_log"];

  // Vessel Fields
  const vesselGeneralFields: any = ["max_temperature"];
  const vesselFields = [
    ...vesselReactorTextFields.map(({ value }) => value),
    ...vesselSelectFields.map(({ value }) => value),
  ];
  const vesselAttachments = attachmentsCommon;

  // Reactor Fields
  const reactorGeneralFields: any = ["max_temperature"];
  const reactorFields = [
    ...vesselReactorTextFields.map(({ value }) => value),
    ...reactorSelectFields.map(({ value }) => value),
  ];

  const reactorAttachments = attachmentsCommon;

  // Distillation Fields
  const distillationGeneralFields = [
    "type", "heat_exchanger", "packing_type",
    "packing_material", "tray_type", "auxiliary_units",
  ];
  const distillationFields = [
    ...distillationTextFields.map(({ value }) => value),
  ];

  const distillationAttachments = attachmentsCommon;

  // Filtration Fields
  const filtrationGeneralFields = [
    "type", "filter_material", "solvent_wash",
    "working_pressure", "filter_backwash", "heated",
    "mixer", "cip",
  ];

  const filtrationFields = [
    ...filtrationTextFields.map(({ value }) => value),
  ];

  const filtrationAttachments = attachmentsCommon;

  // Mixer Fields
  const mixerGeneralFields = [
    "type", "shaft_connector",
  ];
  const mixerFields = [
    ...mixerTextFields.map(({ value }) => value),
  ];

  const mixerAttachments = attachmentsCommon;

  // Evaporator Fields

  const evaporatorGeneralFields = [
    "type", "scrapper", "feeding_method",
    "solvent_recovery", "heating", "cip",
  ];

  const evaporatorFields = [
    ...evaporatorTextFields.map(({ value }) => value),
  ];
  const evaporatorAttachments = attachmentsCommon;

  const fieldsMap = {
    "general": genericFields,
    "vessel": {
      "general": vesselGeneralFields,
      "fields": vesselFields,
      "attachments": vesselAttachments,
    },
    "reactor": {
      "general": reactorGeneralFields,
      "fields": reactorFields,
      "attachments": reactorAttachments,
    },
    "distillation": {
      "general": distillationGeneralFields,
      "fields": distillationFields,
      "attachments": distillationAttachments,
    },
    "filtration": {
      "general": filtrationGeneralFields,
      "fields": filtrationFields,
      "attachments": filtrationAttachments,
    },
    "mixer": {
      "general": mixerGeneralFields,
      "fields": mixerFields,
      "attachments": mixerAttachments,
    },
    "evaporator": {
      "general": evaporatorGeneralFields,
      "fields": evaporatorFields,
      "attachments": evaporatorAttachments,
    },
  };

  let key: any;

  for (key of genericFields) {
    if (hasEmptyValue(payload[key]))
      delete payload[key];
  }

  let equipmentType: any;
  for (equipmentType of equipmentTypes) {
    // @ts-ignore
    for (key of fieldsMap[equipmentType]["general"]) {
      if (hasEmptyValue(payload[key]))
        delete payload[key];
    }
    if (!payload[equipmentType])
      continue;
    // @ts-ignore
    for (key of fieldsMap[equipmentType]["general"]) {
      if (hasEmptyValue(payload[equipmentType][key]))
        delete payload[equipmentType][key];
    }
    // @ts-ignore
    for (key of fieldsMap[equipmentType]["fields"]) {
      if (hasEmptyValue(payload[equipmentType][key]))
        delete payload[equipmentType][key];
    }
    if (payload[equipmentType]["attachments"]) {
      // @ts-ignore
      for (key of fieldsMap[equipmentType]["attachments"]) {
        if (payload[equipmentType]["attachments"] && hasEmptyValue(payload[equipmentType]["attachments"][key]))
          delete payload[equipmentType]["attachments"][key];
      }
      if (isCreate || hasEmptyValue(payload[equipmentType]["attachments"]))
        delete payload[equipmentType]["attachments"];
    }
    if (hasEmptyValue(payload[equipmentType]))
      delete payload[equipmentType];
  }

}
