import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";

const ThermalAnalysis = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData }); 4
    const methods = useFormContext();
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
    }, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'heatCurveDsc': {
                let displayNames = props.material?.solid?.thermal?.attachments?.heatCurveDsc || [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                        return true;
                    }
                });
                if (isDuplicate) {
                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                }
                methods.setValue('thermal.attachments.heatCurveDsc', [
                    ...(methods.getValues('thermal.attachments.heatCurveDsc') || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Heat Curve',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
            }
                break;
            case 'sopFileDsc': {
                let displayNames = props.material?.solid?.thermal?.attachments?.sopFileDsc ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                        return true;
                    }
                });
                if (isDuplicate) {
                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                }
                methods.setValue('thermal.attachments.sopFileDsc', [
                    ...(methods.getValues('thermal.attachments.sopFileDsc') || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'SOP File',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
            }
                break;
            case 'heatCurveTga': {
                let displayNames = props.material?.solid?.thermal?.attachments?.heatCurveTga ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                        return true;
                    }
                });
                if (isDuplicate) {
                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                }
                methods.setValue('thermal.attachments.heatCurveTga', [
                    ...(methods.getValues('thermal.attachments.heatCurveTga') || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Heat Curve 2',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
            }
                break;
            case 'sopFileTga': {
                let displayNames = props.material?.solid?.thermal?.attachments?.sopFileTga ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                        return true;
                    }
                });
                if (isDuplicate) {
                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                }
                methods.setValue('thermal.attachments.sopFileTga', [
                    ...(methods.getValues('thermal.attachments.sopFileTga') || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'SOP File 2',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
            }
                break;
            default:
            // default
        }
    }

    // Remove selected file
    const removeFile = (index: number, name: string) => {
        switch (name) {
            case 'heatCurveDsc':
                methods.setValue('thermal.attachments.heatCurveDsc', (methods.getValues('thermal.attachments.heatCurveDsc') || []).filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'sopFileDsc':
                methods.setValue('thermal.attachments.sopFileDsc', (methods.getValues('thermal.attachments.sopFileDsc') || []).filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'heatCurveTga':
                methods.setValue('thermal.attachments.heatCurveTga', (methods.getValues('thermal.attachments.heatCurveTga') || []).filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'sopFileTga':
                methods.setValue('thermal.attachments.sopFileTga', (methods.getValues('thermal.attachments.sopFileTga') || []).filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <h2 className="head-bg text-center">DSC</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="tg_onset_dsc">
                                    <span className="form-label">Tg Onset (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.tg_onset_dsc"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.tg_onset_dsc ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.tg_onset_dsc}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="first_melting_point_dsc">
                                    <span className="form-label">First Melting Point (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.first_melting_point_dsc"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.first_melting_point_dsc ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.first_melting_point_dsc}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="second_melting_point_dsc">
                                    <span className="form-label">Second Melting Point (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.second_melting_point_dsc"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.second_melting_point_dsc ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.second_melting_point_dsc}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="degradation_temperature_dsc">
                                    <span className="form-label">Degradation Temperature (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.degradation_temperature_dsc"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.degradation_temperature_dsc ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.degradation_temperature_dsc}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="eln_number_dsc">
                                    <span className="form-label">ELN Number</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.eln_number_dsc"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.eln_number_dsc ?? ""}
                                            render={({ field }) => <input className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number_dsc}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="method_name_dsc">
                                    <span className="form-label">Method Name/#</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.method_name_dsc"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.method_name_dsc ?? ""}
                                            render={({ field }) => <input className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name_dsc}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label
                                    className="ip-wrap"
                                    htmlFor="regulatory_filing_dsc"
                                >
                                    <span className="form-label">
                                        Regulatory Filing
                                    </span>
                                    <div className="input-wrap select-outer">
                                        {!props.readable ? (
                                            <Controller
                                                name="thermal.regulatory_filing_dsc"
                                                control={methods.control}
                                                defaultValue={methods.control._formValues?.thermal?.regulatory_filing_dsc ?? ""}
                                                render={({ field }) =>
                                                    <select className="theme-ip" {...field}>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>}
                                            />
                                        ) : (
                                            <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing_dsc}</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiFileUpload
                                title="Heat Curve"
                                name="heatCurveDsc"
                                handleFileChange={props?.handleFileChange ?? handleFileChange}
                                removeFile={props?.removeFile ?? removeFile}
                                attachments={props?.attachments?.heatCurveDsc || methods.getValues('thermal.attachments.heatCurveDsc')}
                                readable={props?.readable}
                                downloadFile={props?.downloadFile}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiFileUpload
                                title="SOP File, Instrument Information"
                                name="sopFileDsc"
                                handleFileChange={props?.handleFileChange ?? handleFileChange}
                                removeFile={props?.removeFile ?? removeFile}
                                attachments={props?.attachments?.sopFileDsc || methods.getValues('thermal.attachments.sopFileDsc')}
                                readable={props?.readable}
                                downloadFile={props?.downloadFile}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 divider-left">
                    <h2 className="head-bg text-center">TGA</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="kf_water_content_tga">
                                    <span className="form-label">KF Water Content (w%)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.kf_water_content_tga"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.kf_water_content_tga ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.kf_water_content_tga}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="mass_loss_tga">
                                    <span className="form-label">Mass Loss (w%)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.mass_loss_tga"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.mass_loss_tga ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.mass_loss_tga}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="evaporation_temperature_tga">
                                    <span className="form-label">Evaporation Temperature (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.evaporation_temperature_tga"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.evaporation_temperature_tga ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.evaporation_temperature_tga}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="degradation_temperature_tga">
                                    <span className="form-label">Degradation Temperature (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.degradation_temperature_tga"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.degradation_temperature_tga ?? ""}
                                            render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.degradation_temperature_tga}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="eln_number_tga">
                                    <span className="form-label">ELN Number</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.eln_number_tga"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.eln_number_tga ?? ""}
                                            render={({ field }) => <input className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number_tga}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="method_name_tga">
                                    <span className="form-label">Method Name/#</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="thermal.method_name_tga"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.thermal?.method_name_tga ?? ""}
                                            render={({ field }) => <input className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name_tga}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label
                                    className="ip-wrap"
                                    htmlFor="regulatory_filing_tga"
                                >
                                    <span className="form-label">
                                        Regulatory Filing
                                    </span>
                                    <div className="input-wrap select-outer">
                                        {!props.readable ? (
                                            <Controller
                                                name="thermal.regulatory_filing_tga"
                                                control={methods.control}
                                                defaultValue={methods.control._formValues?.thermal?.regulatory_filing_tga ?? ""}
                                                render={({ field }) =>
                                                    <select className="theme-ip" {...field}>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>}
                                            />
                                        ) : (
                                            <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing_tga}</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiFileUpload
                                title="Heat Curve"
                                name="heatCurveTga"
                                readable={props?.readable}
                                downloadFile={props?.downloadFile}
                                handleFileChange={props?.handleFileChange ?? handleFileChange}
                                removeFile={props?.removeFile ?? removeFile}
                                attachments={props?.attachments?.heatCurveTga || methods.getValues('thermal.attachments.heatCurveTga')}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiFileUpload
                                title="SOP File, Instrument Information"
                                name="sopFileTga"
                                readable={props?.readable}
                                downloadFile={props?.downloadFile}
                                handleFileChange={props?.handleFileChange ?? handleFileChange}
                                removeFile={props?.removeFile ?? removeFile}
                                attachments={props?.attachments?.sopFileTga || methods.getValues('thermal.attachments.sopFileTga')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThermalAnalysis;
