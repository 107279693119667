import React, { useRef } from "react";

function dispatchCloseChatBot(target: any) {
  target.dispatchEvent(new CustomEvent(
    "close-chatbot",
    {
      bubbles: true,
    },
  ));
}

export default function Header({}) {
  const btnRef = useRef<any>();
  return <div className="flex bg-input py-3 px-4 rounded">
    <span className="font-semibold text-sm text-color">Generative AI Assistant</span>
    <button ref={btnRef} className="ml-auto font-semibold" type="button"
            onClick={() => dispatchCloseChatBot(btnRef.current)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="14px" height="14px">
        <path
          d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
      </svg>
    </button>
  </div>;
}