import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import { RootState } from "../../redux/store";
import { sortDescByField, sortAscByField, sortByField } from "../../utils/common";

import { useUser } from "../../components/context/UserContext";
import CustomLoader from "../../components/common/CustomLoader";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import Sortable from './Sortable';
import DeleteConfirmModal from '../modals/deleteConfirmModal';
import { set } from 'lodash';
import { refreshSession } from '@okta/okta-auth-js';
import SimulationService from '../../services/simulationService';
import FileService from '../../services/fileService';
// components
const LaurasModels = (props: any) => {
  
    const dispatch = useDispatch();
    const methods = useFormContext();
    const {headerFilters, model3Saved} = props;

    useEffect(() => {
      getModel3Data()
  }, [headerFilters, model3Saved]);

  // user context
  const { user } = useUser();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';
  //let model3Simulations = useSelector((state: RootState) => state.model3Simulations.items);

  // states
  let [model3Simulations, setModel3Simulations] = useState<any>();
  const [sortBy, setSortBy] = useState<string>('y')
  const [sortOrder, setSortOrder] = useState<string>('desc')
  const [bottom, setBottom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  
  const [deletableId, setDeletableId] = useState<any>(null)
  const [open, setOpen] = useState<boolean>(false)
  
  const deleteSolubility = (dataObject: any) => {
    setDeletableId(dataObject.id)
    setOpen(true);
  }

  
  let count = 0;

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setBottom(count++)
    }
  };

  const [isActive, setActive] = useState(false);

  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  useEffect(() => {
    if (auth?.claims?.uid) {
      getModel3Data();
    }
  }, [auth?.claims]);

  const getModel3Data = async () => {
    setLoading(true); // enable loading
    const payload = {
      ...headerFilters,
      uid: `${auth?.claims?.uid}`,
    };

    const res = await SimulationService.getModel3Data(payload);
    if (res?.data?.code === 200) {
     const result = res?.data;
      if (Array.isArray(result.body)) {
        setModel3Simulations(result.body);
        setLoading(false)
      } else {
        setModel3Simulations([])
        setLoading(false)
      }
    } else {
      setLoading(false)
      dispatch(alertOpenAction('Oops ! something went wrong.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }
  
  
  if (!auth?.claims) {
    return <CustomLoader />
  }

  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite??false;

  const  manageSort =  (newSortBy: string) => {

    console.log('HERERERRERE');

    let newSortOrder: 'asc' | 'desc' = "desc";
    if(newSortBy == sortBy){
      newSortOrder = sortOrder == "asc"  ? "desc" : "asc";
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);

    model3Simulations = sortByField(model3Simulations, newSortBy, newSortOrder);
    setModel3Simulations(model3Simulations);

  }

  const deleteHandle =  async ( )=>{
    setOpen(false); // enable loading
    setLoading(true); // enable loading
    const payload = {
      id: deletableId,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await SimulationService.deleteSimulation(payload);
    if (res?.data?.code === 200) {
      await getModel3Data();
    }

    setLoading(false);
  }
  
  const downloadFile = async (file:string)=>{
    setLoading(true); // enable loading
        const res = await FileService.create("/files/download", {
            file: file,
        });
        
        if (res?.status === 200) {
          
            const fileRes = await fetch(res?.data.body);
            const blob = await fileRes.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = file;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            //dispatch(alertOpenAction('File downloaded successfully.', 'success'));
            setLoading(false); // disable loading
            dispatch(alertOpenAction('Please wait, downloading...', 'success'));
            setTimeout(() => dispatch(alertCloseAction()));
        } else {
          setLoading(false); // disable loading
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
  }
  
  const refreshModel3Data = async () => {
    await getModel3Data();
  }

  return (
    <>
      {loading ?
              <div className="theme-loader show fixed">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div>
              :
              <div className={`right ${(model3Simulations && model3Simulations?.length) ? '' : 'full-flex'}`}>
                {!(model3Simulations && model3Simulations?.length) ?
                  <div>
                    <h1>No data Found !</h1>
                  </div> :
                  <>
                  <DeleteConfirmModal
                  open={open}
                  confirmMessage="Are you sure, do you want to delete this experiment?"
                  setOpen={setOpen}
                  deleteHandle={deleteHandle}
        
                  />
                  <div className='text-right sortable-link'> <button onClick={() => { refreshModel3Data() }} className='pr-5 pb-5'><i className='fa fa-refresh'></i></button></div>
                  <div className="table-outer model-3-table">
                    <div className="table-responsive theme-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              <Sortable 
                              title="Solvent"
                              fieldName="solvent"
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort}></Sortable>
                              
                            </th>
                            <th>
                              <Sortable 
                                title="Temperature (C)"
                                fieldName="x"
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                manageSort={manageSort}></Sortable>
                            </th>
                            <th>
                              <Sortable 
                                title="Image"
                                fieldName="file_path"
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                manageSort={manageSort}></Sortable>
                            </th>
                            <th>
                              <Sortable 
                                title="Turbidity"
                                fieldName="turbidity_measured"
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                manageSort={manageSort}
                                ></Sortable>
                            </th>
                            <th>
                              <Sortable 
                                title="Solubility (mg/ml)"
                                fieldName="y"
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                manageSort={manageSort}
                                ></Sortable>
                            </th>
                            <th className="w-10">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {model3Simulations.map((m3: any, index: any) => (
                            
                              <tr key={m3.id}>
                                
                                <td className="text-center">{m3?.solvent}</td>
                                <td className="text-center">{m3.x}</td>
                                <td className="text-center">
                                
                                  <span className="ml-auto full-flex">
                                  {m3.file_path.length <= 15 ?  m3.file_path : m3.file_path.substring(0, 15) + '...'} <i className="fa-solid fa-download close ml-3 block sortable-link" onClick={() => downloadFile(m3.file_path)} />
                                    </span>
                                  </td>
                                <td className="text-center">{m3?.turbidity_measured}</td>
                                <td className="text-center">{m3.y}</td>
                                <td className="w-10">
                                  <div className="tb-actions">
                                    {hasWritePermission && (
                                      <button data-cy={`delete-saved-simulation-${index + 1}`} type="button" className="icon-btn sm plain" title="Delete" onClick={() => { deleteSolubility(m3) }}>
                                        <i className="fa-solid fa-trash" />
                                      </button>
                                    )}
                                    
                                  </div>
                                </td>
                              </tr>
                              
                            
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </>
                }
              </div>
            }
      </>
  )
}

export default LaurasModels;