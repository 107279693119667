import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";

const Hygroscopicity = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData });
    const methods = useFormContext();
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
    }, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'isothermCurve': {
                let displayNames = props.material?.solid?.hygroscopicity?.attachments?.isothermCurve ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                        return true;
                    }
                });
                if (isDuplicate) {
                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                }
                methods.setValue('hygroscopicity.attachments.isothermCurve', [
                    ...(methods.getValues('hygroscopicity.attachments.isothermCurve') || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Isotherm Curve',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
            }
                break;
            case 'hygroscopicity2':
                let displayNames = props.material?.solid?.hygroscopicity?.attachments?.hygroscopicity2 ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                        return true;
                    }
                });
                if (isDuplicate) {
                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                }
                methods.setValue('hygroscopicity.attachments.hygroscopicity2', [
                    ...(methods.getValues('hygroscopicity.attachments.hygroscopicity2') || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Hygroscopicity 2',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    // Remove selected file
    const removeFile = (index: number, name: string) => {
        switch (name) {
            case 'isothermCurve':
                methods.setValue('hygroscopicity.attachments.isothermCurve', (methods.getValues('hygroscopicity.attachments.isothermCurve') || []).filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'hygroscopicity2':
                methods.setValue('hygroscopicity.attachments.hygroscopicity2', (methods.getValues('hygroscopicity.attachments.hygroscopicity2') || []).filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="mass_update">
                            <span className="form-label">Mass Uptake 25C/80%RH</span>
                            {!props.readable ? (
                                <Controller
                                    name="hygroscopicity.mass_update"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.hygroscopicity?.mass_update ?? ""}
                                    render={({ field }) => <input className="theme-ip" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.mass_update}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="category">
                            <span className="form-label">Category</span>
                            {!props.readable ? (
                                <Controller
                                    name="hygroscopicity.category"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.hygroscopicity?.category ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.category}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Isotherm Curve"
                        name="isothermCurve"
                        attachments={props?.attachments?.isothermCurve ?? methods.getValues('hygroscopicity.attachments.isothermCurve')}
                        handleFileChange={props?.handleFileChange ?? handleFileChange}
                        removeFile={props?.removeFile ?? removeFile}
                        readable={props?.readable}
                        downloadFile={props?.downloadFile}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="eln_number">
                            <span className="form-label">ELN Number</span>
                            {!props.readable ? (
                                <Controller
                                    name="hygroscopicity.eln_number"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.hygroscopicity?.eln_number ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="method_name">
                            <span className="form-label">Method Name/#</span>
                            {!props.readable ? (
                                <Controller
                                    name="hygroscopicity.method_name"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.hygroscopicity?.method_name ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label
                            className="ip-wrap"
                            htmlFor="solidForm"
                        >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
                            <div className="input-wrap select-outer">
                                {!props.readable ? (
                                    <Controller
                                        name="hygroscopicity.regulatory_filing"
                                        control={methods.control}
                                        defaultValue={methods.control._formValues?.hygroscopicity?.regulatory_filing ?? ""}
                                        render={({ field }) =>
                                            <select className="theme-ip" {...field}>
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        }
                                    />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="SOP File, Instrument Information"
                        name="hygroscopicity2"
                        attachments={props?.attachments?.hygroscopicity2 ?? methods.getValues('hygroscopicity.attachments.hygroscopicity2')}
                        handleFileChange={props?.handleFileChange ?? handleFileChange}
                        removeFile={props?.removeFile ?? removeFile}
                        readable={props.readable}
                        downloadFile={props?.downloadFile}
                    />
                </div>
            </div>
        </>
    );
};

export default Hygroscopicity;