import CrystallizationForm from "./CrystallizationForm";
import MaxSolubillityTable from "./MaxSolubillityTable";
import ContourGraph from "./ContourGraph";
import React from "react";

export default function SolubilityScreening({
  handleFormSubmit,
  responseData,
  setResponseData,
}: {
  handleFormSubmit: any;
  responseData: any;
  setResponseData: any;
}) {
  return (
    <div className="grid grid-cols-12 solubility-screening-table">
      <div className="sm:col-span-5 lg:col-span-3">
        <CrystallizationForm
          onSubmit={handleFormSubmit}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-1"></div>
      <div className="sm:col-span-6 lg:col-span-8">
        {responseData && (
          <MaxSolubillityTable data={responseData.solubility_data} />
        )}
        {responseData && <ContourGraph plot={responseData.plot1} />}
      </div>
    </div>
  );
}
