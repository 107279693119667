import http from "./httpService";

function askAmazonQ(query: string) {
  return http.post("https://9kxnjtsacg.execute-api.us-east-1.amazonaws.com/dev/aws-q/get", {
    query,
  });
}

export default {
  askAmazonQ,
};
