import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useForm } from "react-hook-form";

// components
import Layout from "../../components/layout";
import FilterSidebar from "../../components/material/FilterSidebar";
import CustomLoader from "../../components/common/CustomLoader";

// modals
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import ExportFieldsModal from "../../components/modals/ExportFieldsModal";
import HelpModal from "../../components/modals/HelpModal";

// services
import MaterialService from "../../services/materialService";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction, materialsAction } from "../../redux/actions/materialActions";

// props
import { RootState } from "../../redux/store";

// helpers
import { MaterialListHelp } from "../../utils/helpContent";
import { ERROR_MESSAGE } from "../../utils/constant";

import Sortable from "../../components/solubility/Sortable";
import { sortByField } from "../../utils/common";
import BackButton from "../../components/common/BackButtonNonForm";

const MaterialList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";
  const materials = useSelector((state: RootState) => state.material.items);

  const { setValue, watch } = useForm({ defaultValues: { selectedRows: [] } });

  // states
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>();
  const [bottom, setBottom] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [selectAllRows, setSelectAllRows] = useState<boolean>(false);
  const [isActive, setActive] = useState<boolean>(false);
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [cloneId, setCloneId] = useState<any>();
  const [filters, setFilters] = useState<any>({
    gas: {},
    liquid: {},
    phase: "",
    shelf_life: { operation: "", value: "" },
    smiles: "",
    solid: {},
    vendor_name: [],
  });

  const selectedRows: any = watch("selectedRows");
  const [materialList, setMaterialList] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  function manageSort(newSortBy: string) {
    let newSortOrder: "asc" | "desc" = "desc";
    if (newSortBy == sortBy) {
      newSortOrder = sortOrder == "asc" ? "desc" : "asc";
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setMaterialList(sortByField(materialList, newSortBy, newSortOrder));
  }


  useEffect(() => {
    if (materials && Array.isArray(materials))
      setMaterialList(materials);
  }, [materials]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let count = 0;
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setBottom(count++);
    }
  };

  useEffect(() => {
    if (auth?.claims?.uid && filters) {
      searchMaterials();
    }
  }, [auth?.claims, filters]);

  const searchMaterials = async () => {
    // setValue('selectedRows', []);

    const payload = {
      uid: `${auth?.claims?.uid}`,
      LastEvaluatedKey: lastEvaluatedKey ?? "",
      ...filters,
    };
    setLoading(true); // enable loading
    const res = await MaterialService.getAll(payload);
    setLoading(false); // disable loading
    if (res?.data?.code === 200) {
      if (res?.data?.body?.Items) {
        dispatch(materialsAction(res?.data?.body?.Items ?? []));
      } else {
        dispatch(materialsAction([]));
      }
    } else {
      dispatch(alertOpenAction(ERROR_MESSAGE, "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  //To expand div : show/hode fields
  const handleExpand = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  // method to edit material functionality
  const editMaterial = (data: any) => {
    history.push({
      pathname: `/materials/${data?.id}/update`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  // method to view material functionality
  const viewMaterial = (data: any) => {
    dispatch(materialAction(data));
    history.push({
      pathname: `/materials/${data?.id}/view`,
      state: { id: `${data?.id}` },
    });
  };

  // method to clone material functionality
  const cloneMaterial = (data: any) => {
    setOpenCloneModal(true);
    setCloneId(data?.id);
  };

  const handleFilters = (data: any) => {
    setFilters(data);
  };

  const toggleClass = () => {
    setActive(!isActive);
    const filterButton = document.querySelector(".filter-responsive") as HTMLButtonElement;
    if (filterButton && filterButton.classList.contains("alter")) {
      filterButton.classList.remove("alter");
    } else {
      filterButton.classList.add("alter");
    }
  };

  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    const allRowIds = materials?.map((row: any) => row.id);
    setSelectAllRows(checked);
    setValue("selectedRows", checked ? allRowIds : []);
  };

  const handleRowSelect = (rowId: any) => {
    const newSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id: any) => id !== rowId)
      : [...selectedRows, rowId];

    setValue("selectedRows", newSelectedRows);
  };

  const handleExportModal = () => {
    selectedRows?.length ? setOpen(true) :
      dispatch(alertOpenAction("Please select rows you want to export.", "error"));
    setTimeout(() => dispatch(alertCloseAction()));
  };

  function handleReset() {
    setValue("selectedRows", []);
  }

  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  if (!auth?.claims) {
    // Custom loader
    return <CustomLoader />;
  }

  return (
    <Layout title="Material Screening">
      <ExportFieldsModal
        open={open}
        setOpen={setOpen}
        selectedRows={selectedRows}
        filters={filters}
        selectAllChecked={selectAllRows}
      />
      <ConfirmCloneModal
        open={openCloneModal}
        setOpen={setOpenCloneModal}
        type="Material"
        cloneId={cloneId}
        uid={auth?.claims?.uid ?? ""}
        operation="cloneFromList"
      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={MaterialListHelp.title}
        content={MaterialListHelp.content} />
      <div className="sec-info control-head">
        <BackButton />
        <div className="head">
          <h1 className="head-lg">All Materials Screening</h1>
        </div>
        <div className="right">
          <Link to="#" title="Export" className="icon-btn" onClick={() => handleExportModal()}>
            <i className="fa-solid fa-file-export"></i>
          </Link>
          <Link to="/materials" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link to="#" title="Help" className="icon-btn alter" onClick={() => {
            setOpenHelp(true);
          }}>
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>

      <div className="theme-card sm filter-card">
        <div className="body p-0">
          <div className="row sm-row row-responsive">
            <div className={isActive ? "left click-block" : "left"}>
              <FilterSidebar filters={handleFilters} user={user} handleReset={handleReset} />
            </div>
            {loading ? <CustomLoader /> : (
              <div
                className={`right ${materialList.length ? "" : "full-flex"}`}>
                {!loading && !materialList.length ? (
                  <div>
                    <h1>No data Found !</h1>
                  </div>
                ) : (
                  <div className="table-outer">
                    <div className="table-responsive theme-table">
                      <table className="table">
                        <thead>
                        <tr>
                          <th className="align">
                            <input
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={materials?.length && selectedRows?.length === materials?.length}
                            />
                          </th>
                          <th className="w-1"></th>
                          <th>
                            <Sortable
                              title="Material"
                              fieldName="material_name"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Vendor"
                              fieldName="vendor_name"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Phase"
                              fieldName="phase"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th>
                            <Sortable
                              title="Lot Number"
                              fieldName="lot_number"
                              alignCenter={false}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                              manageSort={manageSort} />
                          </th>
                          <th className="w-10">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {materialList.length ? materialList.map((material: any, index: number) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={() => handleRowSelect(material?.id)}
                                  checked={selectedRows.includes(material?.id)}
                                />
                              </td>
                              <td className="w-1 expand-cell">
                                <button
                                  type="button"
                                  className="icon-btn sm plain"
                                  onClick={() => handleExpand(index)}
                                >
                                  <i
                                    className={`fa-solid fa-chevron-${expandedRows.includes(index) ? "down" : "right"}`}
                                  />
                                </button>
                              </td>
                              <td className="f-heavy">{material.material_name}</td>
                              <td>{material.vendor_name.replace("Takeda", "Procegence")}</td>
                              <td>{material.phase}</td>
                              <td>{material.lot_number}</td>
                              <td className="w-10">
                                <div className="tb-actions">
                                  {hasWritePermission && (
                                    <button type="button" className="icon-btn sm plain" title="Edit" onClick={() => {
                                      editMaterial(material);
                                    }}>
                                      <i className="fa-solid fa-pen" />
                                    </button>
                                  )}
                                  <button type="button" className="icon-btn sm plain" title="View" onClick={() => {
                                    viewMaterial(material);
                                  }}>
                                    <i className="fa-solid fa-eye" />
                                  </button>
                                  {hasWritePermission && (
                                    <button type="button" className="icon-btn sm plain" title="Clone" onClick={() => {
                                      cloneMaterial(material);
                                    }}>
                                      <i className="fa-solid fa-clone" />
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                            {expandedRows.includes(index) && (
                              <tr className="child-data">
                                <td colSpan={10}>
                                  <table className="sm-table">
                                    <tbody>
                                    <tr>
                                      <td className="f-heavy w-20 ">Abbreviation:</td>
                                      <td>{material.abbreviation ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Shelf-life (month):</td>
                                      <td>{material?.shelf_life ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Brand Name</td>
                                      <td>{material.brand_name ?? "N/A"}</td>
                                    </tr>
                                    <tr>
                                      <td className="f-heavy w-20">Incompatibility:</td>
                                      <td>{material.incompatibility ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Synonyms:</td>
                                      <td>{material.synonyms ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Melting Point: (C)</td>
                                      <td>{material.melting_point ?? "N/A"}</td>
                                    </tr>
                                    <tr>
                                      <td className="f-heavy w-20">Process Name:</td>
                                      <td>{material.process_name ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Boiling Point (C):</td>
                                      <td>{material.boiling_point ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Molecular Weight (g/mol):</td>
                                      <td>{material.molecular_weight ?? "N/A"}</td>
                                    </tr>
                                    <tr>
                                      <td className="f-heavy w-20">Manufacturing Lot No:</td>
                                      <td>{material.manufacturing_number ?? "N/A"}</td>
                                      <td className="f-heavy w-20">CAS Number:</td>
                                      <td>{material.cas_number ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Price ($/g):</td>
                                      <td>{material.price ?? "N/A"}</td>
                                    </tr>
                                    <tr>
                                      <td className="f-heavy w-20">Regulatory Filing Data:</td>
                                      <td>{material.regulatory_filling_data ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Chemical Formula:</td>
                                      <td>{material.chemical_formula ?? "N/A"}</td>
                                      <td className="f-heavy w-20">Purity (%):</td>
                                      <td>{material.purity ?? "N/A"}</td>
                                    </tr>
                                    <tr>
                                      <td className="f-heavy w-20">Chemical Safety:</td>
                                      <td>{material.chemical_safety ?? "N/A"}</td>
                                      <td className="f-heavy w-20">SMILES:</td>
                                      <td>{material.smiles ?? "N/A"}</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        )) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MaterialList;
